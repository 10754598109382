
import React from 'react';
import { useQuery } from '@apollo/client';
import apolloClient from '../queries/Apollo';
import { GET_NEWS } from '../queries/GraphQL_Query';
import parse from 'html-react-parser';
import { marked } from 'marked';
import { useParams } from 'react-router-dom';
import LoadingIndicator from './LoadingIndicator';
import { useTranslation } from 'react-i18next';

function RegulaminAplikacji() {

    const { i18n } = useTranslation();

    const { categoryId } = useParams();

    const { loading, error, data } = useQuery(GET_NEWS, {
        variables: { categoryId },
        client: apolloClient,
    });

    if (loading) return <LoadingIndicator />;
    if (error) return <p>Error: {error.message}</p>;

    const description = i18n.language === 'en' ? data.allPageEntries.nodes[0].descriptionEn : data.allPageEntries.nodes[0].description;
    const title = i18n.language === 'en' ? data.allPageEntries.nodes[0].nameEn : data.allPageEntries.nodes[0].name;
    const basePhotoUrl = "http://146.59.55.173/";

    const dom = new DOMParser().parseFromString(description, 'text/html');
    dom.querySelectorAll('img').forEach(img => {
        const src = img.getAttribute('src');
        if (src.startsWith('media/')) {
            img.setAttribute('src', basePhotoUrl + src);
        }
    });
    const modifiedDescription = dom.body.innerHTML;

    const html = marked.parse(modifiedDescription);

    return (
        <div className="content">
            <h1>{title}</h1>
            <div className="content-text">
                {parse(html)}
            </div>
        </div>
    );
}

export default RegulaminAplikacji;