
import React from "react";
import "./ContactSection.css";
import {ReactComponent as TomekDIcon} from "../assets/icons/TomekD.svg";
import '../i18n';
import { useTranslation } from 'react-i18next';

function ContactSection() {

    const { t } = useTranslation();

    return (
        <div className="contact-section">
            <h1>{t('contact_section.Skontaktuj_się_z_nami')}</h1>
            <div className="columns-container">
                <div className="column">
                    <TomekDIcon />
                    <h2>Tomasz Dobrowolski</h2>
                    <h3>{t('contact_section.stanowisko1')}</h3>
                    <p>{t('contact_section.ul')}Jezuicka 1<br></br>
                    85-102 Bydgoszcz<br></br>
                    {t('contact_section.tel')}52 58 58 913<br></br>
                    {t('contact_section.fax')}52 58 58 446<br></br>
                    {t('contact_section.email')}kontakt@aktywne.miasta.pl</p>
                </div>
                {/* <div className="column">
                    <TomekDIcon />
                    <h2>Tomasz Dobrowolski</h2>
                    <h3>{t('contact_section.stanowisko2')}</h3>
                    <p>{t('contact_section.ul')}Kolejowa 3<br></br>
                    05-092 Łomianki<br></br>
                    {t('contact_section.tel')}22 751 44 44<br></br>
                    {t('contact_section.fax')}22 751 44 45<br></br>
                    {t('contact_section.email')}email@email.pl</p>
                </div> */}
            </div>
        </div>
    );
}

export default ContactSection;