// Sidebar.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faTrophy,
  faGamepad,
  faMedal,
  faFlag,
  faBell,
  faUsers,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useUserContext } from './UserContext';
import { Logout } from './Logout';

function Sidebar({ isSidebarOpen, toggleSidebar }) {
  const {
    firstName,
    lastName,
    profileImage,
    cityId, // Assuming cities_id is provided in UserContext
  } = useUserContext();

  const [cityName, setCityName] = useState(''); // State to store city name

  console.log(cityId);

  // Fetch city name based on cities_id
  useEffect(() => {
    if (cityId) {
      fetch(`https://146.59.55.173/api/cities/${cityId}/`)
        .then((response) => response.json())
        .then((data) => setCityName(data.name || 'Unknown City'))
        .catch((error) => {
          console.error("Error fetching city name:", error);
          setCityName('Unknown City');
        });
    }
  }, [cityId]);

  return (
    <div
      className={`fixed inset-y-0 left-0 transform ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } lg:translate-x-0 lg:relative w-80 lg:w-96 h-screen flex flex-col justify-between bg-white shadow-lg z-50 transition-transform duration-300 ease-in-out`}
    >
      {/* Top section: user info */}
      <div>
        <Link
          to="/account/user"
          className="flex items-center px-6 py-8 text-[#016623] hover:bg-gray-100"
          onClick={toggleSidebar} // Close sidebar on link click for mobile
        >
          <img
            src={profileImage || 'https://via.placeholder.com/100'}
            alt="User"
            className="w-16 h-16 lg:w-20 lg:h-20 rounded-full mr-4"
          />
          <div>
            <strong className="text-base lg:text-lg font-semibold">
              {firstName} {lastName}
            </strong>
            <p className="text-xs lg:text-sm">{cityName || 'Unknown City'}</p>
          </div>
        </Link>

        <hr className="border-t border-[#016623] mb-4" />

        {/* Navigation section */}
        <nav className="px-6">
          <ul className="space-y-2">
            <li>
              <Link
                to="/account/activities"
                className="flex items-center text-[#454545] hover:bg-gray-300 p-2 rounded-md"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faChartBar} className="mr-3 text-[#016623]" />
                Statystyki i historia
              </Link>
            </li>
            <li>
              <Link
                to="/account/competitions"
                className="flex items-center text-[#454545] hover:bg-gray-300 p-2 rounded-md"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faTrophy} className="mr-3 text-[#016623]" />
                Rywalizacje
              </Link>
            </li>
            <li>
              <Link
                to="/account/login"
                className="flex items-center text-[#454545] hover:bg-gray-300 p-2 rounded-md"
                onClick={Logout}
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-3 text-[#016623]" />
                Wyloguj
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      {/* Footer: logo at the bottom */}
      <div className="mt-auto text-center pb-6">
        <img src="/images/user_account/logo.svg" alt="Logo" className="w-40 mx-auto" />
      </div>
    </div>
  );
}

export default Sidebar;
