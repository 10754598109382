import React from "react";
import "./FunctionSection.css";
import '../i18n';
import { useTranslation } from 'react-i18next';

function FunctionSection() {

    const { t } = useTranslation();

    return (
        <div className="function-container-max-width">
            <div className="function-container">
                <h1>{t('function_section.Funkcjonalność')}</h1>
                <p>{t('function_section.Aplikacja')} <strong>{t('function_section.Aktywne_Miasta')} </strong>{t('function_section.wstep1')}<br/><br/>

                <strong>{t('function_section.wstep2')}</strong><br/><br/>

                {t('function_section.wstep3')} <strong>{t('function_section.Rowerowa_Stolica_Polski')}</strong>.<br/><br/>

                {t('function_section.wstep4')}</p>
            
            </div>
            <div className="function-container-green">
                <div className="function-container-columns-container">
                    <div className="function-container-column">
                        <div className="function-container-column-content">
                            <h3>{t('function_section.Dział_Statystyki')}</h3>
                            <p>
                                <strong>{t('function_section.Statystyki')}</strong><br/>
                                {t('function_section.statystyki_opis1')}<br/>
                                <ul>
                                    <li>{t('function_section.statystyki_opis2')}</li>
                                    <li>{t('function_section.statystyki_opis3')}</li>
                                    <li>{t('function_section.statystyki_opis4')}</li>
                                    <li>{t('function_section.statystyki_opis5')}</li>
                                </ul>
                                <strong>{t('function_section.Historia')}</strong><br/>
                                {t('function_section.historia_opis')}<br/><br/>
                                <strong>{t('function_section.porówanie_trenigów')}</strong><br/>
                                {t('function_section.porównanie_opis1')}<br/>
                                <ul>
                                    <li>{t('function_section.porównanie_opis2')}</li>
                                    <li>{t('function_section.porównanie_opis3')}</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className="function-container-column">
                        <div className="function-container-column-image">
                            <img src={process.env.PUBLIC_URL + '/images/app_stat.png'} alt="Statystyki" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="function-container-columns-container">
                <div className="function-container-column">
                    <div className="function-container-column-image">
                        <img src={process.env.PUBLIC_URL + '/images/2_funkcjonalnosc.png'} alt="Szczegóły" />
                    </div>
                </div>
                <div className="function-container-column">
                    <div className="function-container-column-content">
                        <p>
                            <strong>{t('function_section.Szczegóły_aktywności')}</strong><br/>
                            {t('function_section.szczegóły_opis1')}<br/>
                            <ul>
                                <li>{t('function_section.szczegóły_opis2')}</li>
                                <li>{t('function_section.szczegóły_opis3')}</li>
                                <li>{t('function_section.szczegóły_opis4')}</li>
                            </ul>
                            {t('function_section.szczegóły_opis5')}<br/><br/>
                            <strong>{t('function_section.publikacja_treningu')}</strong><br/>
                            {t('function_section.publikacja_opis1')}<br/>
                            <ul>
                                <li>{t('function_section.publikacja_opis2')}</li>
                                <li>{t('function_section.publikacja_opis3')}</li>
                                <li>{t('function_section.publikacja_opis4')}</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <div className="function-container-green">
                <div className="function-container-columns-container">
                    <div className="function-container-column">
                        <div className="function-container-column-content">
                            <h3>{t('function_section.dział_odznaki')}</h3>
                            <p><strong>{t('function_section.Odznaki')}</strong><br/>
                            {t('function_section.odznaki_opis1')}<br/>
                            <ul>
                                <li>{t('function_section.odznaki_opis2')}</li>
                                <li>{t('function_section.odznaki_opis3')}</li>
                                <li>{t('function_section.odznaki_opis4')}</li>
                                <li>{t('function_section.odznaki_opis5')}</li>
                            </ul>
                            </p>
                        </div>
                    </div>
                    <div className="function-container-column">
                        <div className="function-container-column-image">
                            <img src={process.env.PUBLIC_URL + '/images/3_funkcjonalnosc.png'} alt="AppBadges" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="function-container-columns-container">
                <div className="function-container-column">
                    <div className="function-container-column-image">
                        <img src={process.env.PUBLIC_URL + '/images/4_funkcjonalnosc.png'} alt="AppActivity" />
                    </div>
                </div>
                <div className="function-container-column">
                    <div className="function-container-column-content">
                        <h3>{t('function_section.dział_aktywność')}</h3>
                        <p>{t('function_section.aktywność_opis1')}<br/><br/>
                        {t('function_section.aktywność_opis2')}
                        <ul>
                            <li>{t('function_section.aktywność_opis3')}</li>
                            <li>{t('function_section.aktywność_opis4')}</li>
                            <li>{t('function_section.aktywność_opis5')}</li>
                            <li>{t('function_section.aktywność_opis6')}</li>
                            <li>{t('function_section.aktywność_opis7')}</li>
                            <li>{t('function_section.aktywność_opis8')}</li>
                            <li>{t('function_section.aktywność_opis9')}</li>
                        </ul>
                        {t('function_section.aktywność_opis10')} <strong>{t('function_section.aktywność_opis11')}</strong> {t('function_section.aktywność_opis12')}
                        <ul>
                            <li>{t('function_section.aktywność_opis13')}</li>
                            <li>{t('function_section.aktywność_opis14')}</li>
                            <li>{t('function_section.aktywność_opis15')}</li>
                            <li>{t('function_section.aktywność_opis16')}</li>
                            <li>{t('function_section.aktywność_opis17')}</li>
                        </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FunctionSection;