
import React from "react";
import './Pagination.css';

function Pagination({ currentPage, totalPages, paginate }) {
    const pageNumbers = [];
    const maxPageNumbersDisplayed = 7;
    const halfPageRange = Math.floor(maxPageNumbersDisplayed / 2);

    let startPage = Math.max(1, currentPage - halfPageRange);
    let endPage = Math.min(totalPages, currentPage + halfPageRange);

    // Ajust range to ensure maxPageNumbersDisplayed are displayed
    if (startPage === 1) {
        endPage = Math.min(startPage + maxPageNumbersDisplayed - 1, totalPages);
    } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - maxPageNumbersDisplayed + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    if (totalPages <= 1) {
        return null;
    }

    return (
        <nav>
            <ul className="pagination">
                {currentPage > 1 && (
                    <>
                        <li>
                            <a onClick={() => paginate(1)} href="#!">«</a>
                        </li>
                        <li>
                            <a onClick={() => paginate(currentPage - 1)} href="#!">‹</a>
                        </li>
                    </>
                )}
                {/* Renderowanie numerów stron */}
                {pageNumbers.map(number => (
                    <li key={number} className={number === currentPage ? 'active' : ''}>
                        <a onClick={() => paginate(number)} href="#!">{number}</a>
                    </li>
                ))}
                {currentPage < totalPages && (
                    <>
                        <li>
                            <a onClick={() => paginate(currentPage + 1)} href="#!">›</a>
                        </li>
                        <li>
                            <a onClick={() => paginate(totalPages)} href="#!">»</a>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
}


export default Pagination;
