import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import apolloClient from '../../../queries/ApolloUser';
import { useParams, useNavigate } from 'react-router-dom';
import { useUserContext } from '../components/UserContext';
import { GET_CHALLENGE_DETAILS } from '../../../queries/GraphQL_Query';
import Ranking from '../pages/Ranking'; // Importuj komponent Ranking

const CompetitionDetailView = () => {
  const { id } = useParams();
  const { userId } = useUserContext();
  const [activeTab, setActiveTab] = useState('informacje');
  const navigate = useNavigate();

  // Fetch competition info data
  const { data: infoData, loading: infoLoading, error: infoError } = useQuery(GET_CHALLENGE_DETAILS, {
    client: apolloClient,
    variables: { id: parseInt(id), userId },
  });

  useEffect(() => {
    if (infoData) console.log("Competition Info Data:", infoData);
    if (infoError) console.error("Info Error:", infoError);
  }, [infoData, infoError]);

  const handleTabChange = (tab) => setActiveTab(tab);

  if (infoLoading) return <p>Ładowanie danych...</p>;
  if (infoError) return <p>Błąd ładowania danych: {infoError.message}</p>;

  const competitionInfo = infoData?.allActivitiesCompetitions.nodes[0];

  return (
    <div className="p-4">
      <button onClick={() => navigate(-1)} className="text-[#016623] hover:underline mb-4">
        ← Powrót do rywalizacji
      </button>
      <h1 className="text-green-600 text-2xl font-bold">{competitionInfo?.name}</h1>

      {/* Tabs */}
      <div className="flex justify-between mt-4 mb-6 space-x-8">
        <button
          className={`w-1/2 hpy-4 rounded-full shadow-custom font-semibold ${
            activeTab === 'informacje' ? 'bg-btn_primary text-white' : 'text-[#454545]'
          }`}
          onClick={() => handleTabChange('informacje')}
        >
          Informacje
        </button>
        <button
          className={`w-1/2 py-4 rounded-full shadow-custom font-semibold ${
            activeTab === 'ranking' ? 'bg-btn_primary text-white' : 'text-[#454545]'
          }`}
          onClick={() => handleTabChange('ranking')}
        >
          Ranking
        </button>
      </div>

      {/* Conditional rendering based on active tab */}
      {activeTab === 'informacje' && competitionInfo && (
        <div>
          <p className="text-btn_primary text-lg mt-2 inline">{new Date(competitionInfo.start).toLocaleDateString()}</p>
          <p className='px-2 inline'>-</p>
          <p className="text-btn_primary text-lg mt-2 inline">{new Date(competitionInfo.end).toLocaleDateString()}</p>
          {competitionInfo.activitiesPhotosByCompetitionId.nodes[0]?.image && (
            <div>
              <img
              src={`http://panel.aktywne.miasta.pl/media/${competitionInfo.activitiesPhotosByCompetitionId.nodes[0].image}`}
              alt={competitionInfo.name}
              className="h-100 w-auto rounded-3xl shadow mt-4"
            />
            </div>
          )}
          <p className="text-lg pt-4">{competitionInfo.description}</p>
        </div>
      )}

      {activeTab === 'ranking' && (
    <Ranking competitionId={id} /> 
      )}
    </div>
  );
};

export default CompetitionDetailView;
