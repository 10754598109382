// Header.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import './Header.css';

function Header() {
    const location = useLocation();
    const [content, setContent] = useState('image');

    useEffect(() => {
        if (location.pathname === '/') {
            setContent('mainPageImage');
        } else {
            setContent('image');
        }
    }, [location]);

    return (
        <header className="header-container">
            <div className="navbar-container">
                <Navbar />
            </div>
            {content === 'mainPageImage' && (
                <div className="main-page-image-container">
                    <img src={process.env.PUBLIC_URL + '/images/mainPageImage.jpg'} alt="Main Page" className="main-page-image" />
                </div>
            )}
            {/* {content === 'video' && (
                <video autoPlay loop muted className="header-video">
                    <source src={sampleVideo} type="video/mp4" />
                </video>
            )} */}
            {content === 'image' && (
                <img src={process.env.PUBLIC_URL + '/images/stopka.png'} alt="Header" className="header-image" />
            )}
        </header>
    );
}

export default Header;