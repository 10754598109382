
import React from "react";
import "./NewsSection.css";
import NewsItem from "./NewsSectionItem";
import Pagination from "./features/Pagination";
import { useQuery } from '@apollo/client';
import apolloClient from '../queries/Apollo';
import { GET_NEWS } from '../queries/GraphQL_Query';
import { useState } from "react";
import '../i18n';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from './LoadingIndicator';

function NewsSection() {

    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [categoryId] = useState('1');
    const itemsPerPage = 15;

    const { loading, error, data } = useQuery(GET_NEWS, {
        variables: { categoryId },
        client: apolloClient,
    });

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) return <LoadingIndicator />;
    if (error) return <p>Error: {error.message}</p>;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data?.allPageEntries?.nodes.slice(indexOfFirstItem, indexOfLastItem);

    const totalItems = data?.allPageEntries?.totalCount;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <div className="news"> 
            <h1>{t('news_section.Aktualności')}</h1>    
            <div className="section">
                {currentItems.map(news => (
                    <NewsItem key={news.id} {...news} />
                ))}
            </div>
            <div className="pagination-container">
                <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
            </div>
        </div>
    );
}

export default NewsSection;
