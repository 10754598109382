import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import parseHTML from 'html-react-parser';
import { marked } from 'marked';
import '../i18n';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import apolloClient from '../queries/Apollo';
import { GET_HELP_DETAIL } from '../queries/GraphQL_Query';
import LoadingIndicator from "./LoadingIndicator";
import { useParams } from "react-router-dom";

function HelpDetail() {

    const { id } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    const location = useLocation();
    const baseUrl = "https://aktywne.miasta.pl/media/";
    const { name, description, attachments } = location.state || {};
    
    const { loading, error, data } = useQuery(GET_HELP_DETAIL, {
        variables: { id },
        skip: !!name,
        client: apolloClient,
    });

    if (loading) return <LoadingIndicator />;
    if (error) return <p>Error: {error.message}</p>;

    const mapDataToNews = (data) => {
        return {
            name: data.allPageHelpdesks.nodes[0].name,
            description: data.allPageHelpdesks.nodes[0].description,
            attachments: data.allPageHelpdesks.nodes[0].pageHelpdeskAttachmentsByHelpdeskId.nodes
        };
    }

    const help = data ? mapDataToNews(data) : { name, description, attachments };

    const html = marked.parse(help.description);

    return (
        
        <div className="content">
            <h1 style={{ textAlign: 'left' }}>{help.name}</h1>
            <div className="content-text">
                {parseHTML(html)}
            </div>
            {attachments && attachments.length > 0 && (
                <div className="attachments-container">
                    <p><strong>{t('help_detail.Załączniki')}</strong></p>
                    <div className="attachments-list">
                        {help.attachments.map((attachment, index) => (
                            <a key={index} href={baseUrl + attachment.pageAttachmentByAttachmentId.file} download>
                                {attachment.pageAttachmentByAttachmentId.name}
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </div>
        
    );

    
}

export default HelpDetail;