import React, { useState, useEffect, useRef } from 'react';

function Counter({ targetNumber }) {
    const [count, setCount] = useState(0);
    const increment = targetNumber / 100;
    const counterRef = useRef();
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const currentRef = counterRef.current;
    
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsInView(entry.isIntersecting);
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            }
        );
    
        if (currentRef) {
            observer.observe(currentRef);
        }
    
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    useEffect(() => {
        if (!isInView) {
            return;
        }

        let currentCount = 0;
        const interval = setInterval(() => {
            if (currentCount + increment < targetNumber) {
                currentCount = currentCount + increment;
                setCount(Math.round(currentCount));
            } else {
                clearInterval(interval);
                setCount(targetNumber);
            }
        }, 10);

        return () => {
            clearInterval(interval);
        };
    }, [increment, targetNumber, isInView]);

    return <div className="text" ref={counterRef}>{count.toLocaleString('pl-PL')}</div>;
}

export default Counter;