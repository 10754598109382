
import React from "react";
import './TutorialVideosSection.css';
import TutorialVideosItem from "./TutorialVideosSectionItem";
import Pagination from "./features/Pagination";
import { useQuery } from '@apollo/client';
import apolloClient from '../queries/Apollo';
import {GET_TUTORIAL } from '../queries/GraphQL_Query';
import { useState } from "react";
import '../i18n';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from './LoadingIndicator';

function TutorialVideosSection() {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [categoryId] = useState('1');
    const itemsPerPage = 15;

    const { loading, error, data } = useQuery(GET_TUTORIAL, {
        variables: { categoryId },
        client: apolloClient,
    });

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) return <LoadingIndicator />;
    if (error) return <p>Error: {error.message}</p>;

    const tutorialVideos = data?.allPageMovies?.nodes;

    if (!tutorialVideos || tutorialVideos.length === 0) {
        return <p>No tutorial videos available.</p>;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    
    const currentItems = tutorialVideos.slice(indexOfFirstItem, indexOfLastItem);
    if (!currentItems || currentItems.length === 0) {
        return <p>No tutorial videos available for the current page.</p>;
    }

    const totalItems = tutorialVideos.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <div className="tutorial">
            <h1>{t('tutorial_videos_section.Filmy_instruktażowe')}</h1>
            <div className="section">
                {currentItems.map(tutorial => (
                    <TutorialVideosItem key={tutorial.id} {...tutorial} />
                ))}
            </div>
            <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
        </div>
    );
}

export default TutorialVideosSection;