
import { gql } from '@apollo/client';

export const GET_HELP = gql`
  query HelpQuery {
    allPageHelpdesks(orderBy: ID_ASC) {
      nodes {
        id
        name
        description
        pageHelpdeskAttachmentsByHelpdeskId {
          nodes {
            pageAttachmentByAttachmentId {
              name
              file
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const GET_HELP_DETAIL = gql`
query HelpQuery($id: BigInt = "") {
  allPageHelpdesks(orderBy: ID_ASC, condition: {id: $id}) {
    nodes {
      id
      name
      description
      pageHelpdeskAttachmentsByHelpdeskId {
        nodes {
          pageAttachmentByAttachmentId {
            name
            file
          }
        }
      }
    }
    totalCount
  }
}
`;

export const GET_NEWS = gql`
  query NewsQuery($categoryId: BigInt!) {
    allPageEntries(condition: {visible: true, categoryId: $categoryId}, orderBy: DATE_DESC) {
      nodes {
        id
        name
        nameEn
        date
        description
        descriptionEn
        visible
        partnerId
        pageCategoryByCategoryId {
          id
          name
        }
        pageEntryAttachmentsByEntryId {
          nodes {
            pageAttachmentByAttachmentId {
              file
              name
            }
          }
        }
        pageEntryPhotosByEntryId {
          nodes {
            pagePhotoByPhotoId {
              name
              mainImage
              file
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const GET_NEWS_DETAIL = gql`
query NewsQuery($id: BigInt = "22") {
  allPageEntries(condition: {visible: true, id: $id}, orderBy: DATE_DESC) {
    nodes {
      id
      name
      date
      description
      visible
      partnerId
      pageCategoryByCategoryId {
        id
        name
      }
      pageEntryAttachmentsByEntryId {
        nodes {
          pageAttachmentByAttachmentId {
            file
            name
          }
        }
      }
      pageEntryPhotosByEntryId {
        nodes {
          pagePhotoByPhotoId {
            name
            mainImage
            file
          }
        }
      }
    }
    totalCount
  }
}
`;

export const GET_TUTORIAL = gql`
query TutorialQuery {
  allPageMovies(orderBy: NAME_ASC) {
    nodes {
      id
      name
      url
    }
    totalCount
  }
}
`;

export const GET_USERS_COUNT = gql`
query UsersCountQuery {
  allAccountsUsers {
    totalCount
  }
}
`;

export const GET_CITIES_COUNT = gql`
query CitiesCountQuery($greaterThanOrEqualTo: Date = "") {
  allPagePartners(
    filter: {contractValidTo: {greaterThanOrEqualTo: $greaterThanOrEqualTo}}
  ) {
    totalCount
  }
}
`;

export const GET_FOR_PARTNER = gql`
query ForPartner ($id: BigInt = "") {
  pageForpartnerById(id: $id) {
    name
    description
  }
}
`;

export const GET_DISCRITS = gql`
query GetDiscrits {
  allActivitiesVoivodeships {
    nodes {
      name
    }
  }
}
`;

export const GET_PARTNER_CITY = gql`
query GetPartnerCity($greaterThan: Date = "") {
  allPagePartners(
    condition: {visible: true}
    filter: {contractValidTo: {greaterThan: $greaterThan}}
  ) {
    nodes {
      id
      name
      logo
      description
      contractValidTo
      cityGroupId
      activitiesCitygroupByCityGroupId {
        activitiesVoivodeshipByVoivodeshipId {
          name
          id
          activitiesCitygroupsByVoivodeshipId {
            totalCount
          }
        }
      }
    }
    totalCount
  }
}
`;

export const GET_COMPETITIONS = gql`
query GetCompetitions {
  allActivitiesCompetitions {
    totalCount
  }
}
`;

export const GET_USER_ACTIVITIES = gql`
query UserActivity($userId: BigInt!, $offset: Int = 0) {
  allActivitiesActivityindividuals(
    condition: {finished: true, deleted: false, deletedByUser: false, userId: $userId}
    orderBy: BEGIN_DATE_DESC
    offset: $offset
  ) {
    totalCount
    nodes {
      activityId
      userId
      beginDate
      endTime
      endCalories
      endDistance
      endSpeed
      sportId
    }
  }
}
`
//koordynaty tras aktywności panel użytkownika 
export const GET_USER_GPS = gql`
  query UserActivityGps($in: [BigInt!]!) {
    allActivitiesActivitygps(orderBy: DATE_ASC, filter: {activityId: {in: $in}}) {
      nodes {
        activityId
        latitude
        longitude
        altitude
        speed
        date
        accuracy
        autopause
      }
    }
  }
`;

export const GET_CITY_RANKING = gql`
query CityRanking($filter: FatAggFilter = {}, $first: Int = 50, $offset: Int = 10, $orderBy: [FatAggsOrderBy!]) {
  allFatAggs(orderBy: $orderBy, filter: $filter, first: $first, offset: $offset) {
    pageInfo {
      hasNextPage
    }
    nodes {
      cityGrp
      cityGrpName
      cityGrpImage
      city
      cityName
      cityImage
      grp
      grpName
      grpImage
      subGrp
      subGroupName
      subGroupImage
      usr
      usrFirstName
      usrLastName
      usrImage
      age
      sex
      distance
      calories
      points
    }
  }
}
`

//przelicznik calculatonType punkty za miejsce 
export const GET_NEW_CITY_RANKING = gql`
query NewCityRanking($filter: CopyFromXFatUserPointsByCompetitionFilter = {}, $first: Int = 50, $offset: Int = 0, $orderBy: [CopyFromXFatUserPointsByCompetitionsOrderBy!] = POINTS_DESC) {
  allCopyFromXFatUserPointsByCompetitions(
  orderBy: $orderBy,
  filter: $filter
  first: $first
  offset: $offset
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      cityGrp
      city
      grp
      subGrp
      usr
      usrImage
      firstName
      lastName
      age
      sex
      distance
      points
    }
  }
}
`

//informcje o rywalizacji (szczegóły rywalizacji)
export const GET_CHALLENGE_DETAILS = gql`
query ChallengeDetails($id: BigInt = "1258", $userId: BigInt = "59") {
  allActivitiesCompetitions(condition: {id: $id}) {
    nodes {
      name
      id
      start
      end
      type
      kind
      isDisplayedUntil
      description
      descriptionEn
      startSignUp
      endSignUp
      statueLink
      ownerId
      training
      cityType
      activitiesExcludedcompetitionsByCompetition1Id {
        nodes {
          competition1Id
          competition2Id
          activitiesCompetitionByCompetition2Id {
            name
          }
        }
      }
      activitiesExcludedcompetitionsByCompetition2Id {
        nodes {
          competition1Id
          competition2Id
          activitiesCompetitionByCompetition1Id {
            name
          }
        }
      }
      activitiesCompetitionCitiesByCompetitionId {
        nodes {
          activitiesCityByCityId {
            id
            name
            cityGroupId
            activitiesCitygroupByCityGroupId {
              id
              name
              email
              numberOfResidents
            }
          }
        }
      }
      activitiesCompetitionSportsByCompetitionId {
        nodes {
          activitiesSportBySportId {
            id
            name
          }
        }
      }
      activitiesPhotosByCompetitionId {
        nodes {
          image
        }
      }
      activitiesCompetitionGroupsByCompetitionId {
        totalCount
      }
      activitiesUserjoinedcompetitionsByCompetitionId(condition: {userId: $userId}) {
        totalCount
        nodes {
          id
          competitionId
          activitiesGroupByGroupId {
            id
            name
            typeId
            activitiesCityByCityId {
              id
              name
              activitiesCitygroupByCityGroupId {
                id
                name
              }
            }
          }
          activitiesSubgroupBySubGroupId {
            id
            name
            activitiesGroupByGroupId {
              activitiesSubgroupsByGroupId {
                totalCount
                nodes {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  allCompetitionGroupTypes(condition: {competitionId: $id}) {
    nodes {
      groupTypeId
      groupTypeName
    }
  }
}`

//informacje szczegółowe ranking
export const GET_CHALLANGE_DETAILS_SHORT = gql`
query ChallengeDetailsShort($id: BigInt = "1422", $userId: BigInt = "59") {
  allActivitiesCompetitions(condition: {id: $id}) {
    nodes {
      name
      id
      start
      end
      type
      kind
      isDisplayedUntil
      description
      descriptionEn
      startSignUp
      endSignUp
      statueLink
      ownerId
      training
      cityType
      activitiesCompetitionCitiesByCompetitionId {
        nodes {
          activitiesCityByCityId {
            id
            name
            cityGroupId
            activitiesCitygroupByCityGroupId {
              id
              name
              email
              numberOfResidents
            }
          }
        }
      }
      activitiesPhotosByCompetitionId {
        nodes {
          image
        }
      }
      activitiesCompetitionGroupsByCompetitionId {
        totalCount
      }
      activitiesUserjoinedcompetitionsByCompetitionId(condition: {userId: $userId}) {
        totalCount
        nodes {
          id
          competitionId
          activitiesGroupByGroupId {
            id
            name
            typeId
            activitiesCityByCityId {
              id
              name
              activitiesCitygroupByCityGroupId {
                id
                name
              }
            }
          }
          activitiesSubgroupBySubGroupId {
            id
            name
          }
        }
      }
    }
  }
  allCompetitionGroupTypes(condition: {competitionId: $id}) {
    nodes {
      groupTypeId
      groupTypeName
    }
  }
  allRankByCompetitions(condition: {competition: $id, usr: $userId}) {
    nodes {
      rank
      distance
      points
    }
  }
}`