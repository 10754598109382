import React, { useState } from "react";
import "./AktywneEventSection.css";
import CustomSlider from "./features/CustomSlider";
import { useQuery } from '@apollo/client';
import apolloClient from '../queries/Apollo';
import { GET_NEWS } from '../queries/GraphQL_Query';
import { useNavigate } from 'react-router-dom';
import '../i18n';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from './LoadingIndicator';

function AktywneEventSection() {

    const { t } = useTranslation();

    const [categoryId] = useState('2');

    const { loading, error, data } = useQuery(GET_NEWS, {
        variables: { categoryId },
        client: apolloClient,
    });

    if (loading) return <LoadingIndicator />;
    if (error) return <p>Error: {error.message}</p>;

    const currentItems = data?.allPageEntries?.nodes

    return (
        <div className="aktywne-event-section">
            <h1>{t('event_section.poznaj_nasze_wydarzenia')}</h1>
            <div className="columns-container">

            <CustomSlider>
                {currentItems.map(news => (
                    <AktywneEventItem key={news.id} {...news} />
                ))}
            </CustomSlider>
            </div>
        </div>
    );
}

export default AktywneEventSection;

function AktywneEventItem({ id, name, date, description, pageEntryPhotosByEntryId, pageEntryAttachmentsByEntryId}) {

    const navigate = useNavigate();
    const baseUrl = "https://aktywne.miasta.pl/media/";

    const mainImage = pageEntryPhotosByEntryId.nodes.find(node => node.pagePhotoByPhotoId.mainImage);
    const imageUrl = mainImage ? baseUrl + mainImage.pagePhotoByPhotoId.file : null;

    const handleClick = () => {
        navigate(`/news/${id}`, { 
            state: { 
                name, 
                date, 
                description, 
                imageUrl: imageUrl,
                attachments: pageEntryAttachmentsByEntryId.nodes 
            } 
        });
        window.scrollTo(0, 0);
    }

    return (
        <div onClick={handleClick} className="column">
            <div className="icon">
                <img src={imageUrl} alt={name} />
            </div>
            <div className="text">{name}</div>
        </div>
    );
}