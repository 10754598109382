import React, { useState } from "react";
import './TutorialVideosSection.css';

function TutorialVideosSectionItem({ name, url }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const handleModalClick = (e) => {
        e.stopPropagation();
        if (e.target.classList.contains("modal-overlay")) {
            closeModal();
        }
    }

    const handleButtonClick = (e) => {
        e.stopPropagation();
        closeModal();
    }

    const getVideoIdFromUrl = (url) => {
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return match ? match[1] : null;
    };

    const getThumbnailUrl = (videoId) => {
        return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    };

    const videoId = getVideoIdFromUrl(url);
    const thumbnailUrl = videoId ? getThumbnailUrl(videoId) : '';

    return (
        <div className="tutorial-item" onClick={openModal}>
            <img src={thumbnailUrl} alt="Thumbnail" />
            <h3>{name}</h3>

            {isModalOpen && (
                <div className="modal-overlay" onClick={handleModalClick}>
                    <div className="modal-content">
                        <button className="close-button" onClick={handleButtonClick}>X</button>
                        <iframe
                            width="100%"
                            height="100%"
                            src={url}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TutorialVideosSectionItem;
