import React, { useState, useEffect } from 'react';
import { useUserContext } from '../components/UserContext';

const UserForm = () => {
  const {
    userId,
    firstName,
    lastName,
    email,
    dateOfBirth,
    height,
    weight,
    cityId,
    preferredSports,
    sex,
    profileImage,
    showLocationToFriends,
  } = useUserContext();

  const [userData, setUserData] = useState({
    userId: userId || '',
    name: firstName || '',
    surname: lastName || '',
    email: email || '',
    height: height || '',
    weight: weight || '',
    birthDate: dateOfBirth || '1 JAN 2000',
    gender: sex === '0' ? 'Kobieta' : 'Mężczyzna',
    city: '', // Will fetch city name based on cityId
    sports: preferredSports.reduce((acc, sport) => ({ ...acc, [sport]: true }), {}),
    acceptTerms: true, // Assume terms accepted based on context or localStorage
    allowMarketing: true,
    allowLocation: showLocationToFriends || false,
  });

  const [isCityDropdownOpen, setCityDropdownOpen] = useState(false);
  const [cities, setCities] = useState([]);
  const [sports, setSports] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingSports, setLoadingSports] = useState(true);
  const [cityError, setCityError] = useState(null);
  const [sportsError, setSportsError] = useState(null);

  useEffect(() => {
    setUserData((prevData) => ({
      ...prevData,
      userId,
      name: firstName || '',
      surname: lastName || '',
      email: email || '',
      height: height || '',
      weight: weight || '',
      birthDate: dateOfBirth || '1 JAN 2000',
      gender: sex === '0' ? 'Kobieta' : 'Mężczyzna',
      city: cityId ? cities.find((c) => c.id === cityId)?.name || '' : '',
      sports: preferredSports.reduce((acc, sport) => ({ ...acc, [sport]: true }), {}),
      acceptTerms: true,
      allowMarketing: true,
      allowLocation: showLocationToFriends || false,
    }));
  }, [firstName, lastName, email, dateOfBirth, height, weight, cityId, preferredSports, sex, showLocationToFriends, cities]);

  const fetchCities = async () => {
    setLoadingCities(true);
    try {
      let allCities = [];
      let nextUrl = 'http://146.59.55.173/api/cities/';
      while (nextUrl) {
        const response = await fetch(nextUrl);
        if (!response.ok) throw new Error('Error fetching cities');
        const data = await response.json();
        allCities = [...allCities, ...data.results];
        nextUrl = data.next;
      }
      setCities(allCities);

      // Znalezienie nazwy miasta użytkownika
      const userCity = allCities.find(city => city.id === parseInt(cityId));
      if (userCity) {
        setUserData(prevData => ({
          ...prevData,
          city: userCity.name // Ustaw nazwę miasta na podstawie cityId
        }));
      }
    } catch (error) {
      setCityError('Could not load cities');
      console.error(error);
    } finally {
      setLoadingCities(false);
    }
  };

  const fetchSports = async () => {
    setLoadingSports(true);
    try {
      const response = await fetch('https://146.59.55.173/api/sports/');
      if (!response.ok) throw new Error('Error fetching sports');
      const data = await response.json();
      setSports(data.results);
    } catch (error) {
      setSportsError('Could not load sports');
      console.error(error);
    } finally {
      setLoadingSports(false);
    }
  };

  useEffect(() => {
    fetchSports();
    fetchCities();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSportsChange = (e) => {
    const { name, checked } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      sports: { ...prevData.sports, [name]: checked },
    }));
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleCityChange = (city) => {
    setUserData((prevData) => ({
      ...prevData,
      city: city.name,
    }));
    setCityDropdownOpen(false);
  };

  const toggleCityDropdown = () => {
    setCityDropdownOpen(!isCityDropdownOpen);
  };

  return (
    <div className="p-6 flex justify-between items-start">
      <div className="w-2/5 pr-8">
        <h2 className="text-2xl font-bold mb-4 text-primary-dark uppercase">Dane użytkownika</h2>
        <div className="flex items-center justify-center">
          <img
            src={profileImage || "https://via.placeholder.com/100"}
            alt="User"
            className="w-20 h-20 rounded-full mr-4"
          />
        </div>
        <form className="space-y-4">
          <div>
            <label className="pl-4 text-secondary font-semibold">Imię</label>
            <input
              type="text"
              name="name"
              value={userData.name}
              onChange={handleChange}
              className="border p-2 w-full rounded-3xl h-8"
            />
          </div>
          <div>
            <label className="pl-4 text-secondary font-semibold">Nazwisko</label>
            <input
              type="text"
              name="surname"
              value={userData.surname}
              onChange={handleChange}
              className="border p-2 w-full rounded-3xl h-8"
            />
          </div>
          <div>
            <label className="pl-4 text-secondary font-semibold">Mail</label>
            <input
              type="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              className="border p-2 w-full rounded-3xl h-8"
            />
          </div>
          <div>
            <label className="pl-4 text-secondary font-semibold">Wzrost</label>
            <input
              type="number"
              name="height"
              value={userData.height}
              onChange={handleChange}
              className="border p-2 w-full rounded-3xl h-8"
            />
          </div>
          <div>
            <label className="pl-4 text-secondary font-semibold">Waga</label>
            <input
              type="number"
              name="weight"
              value={userData.weight}
              onChange={handleChange}
              className="border p-2 w-full rounded-3xl h-8"
            />
          </div>
          <div className="mb-4 flex items-center justify-between">
            <label className="block text-secondary font-semibold">Data urodzenia</label>
            <div className="flex items-center bg-gray-100 rounded-full p-2 w-40 justify-center">
              <span className="text-gray-500">{userData.birthDate}</span>
            </div>
          </div>
          <p className="text-sm text-gray-500 mb-4">
            Rekomendujemy wybranie swojego miasta.
          </p>
          <div className="relative mb-6">
            <label className="pl-4 text-secondary font-semibold">Miasto</label>
            <button
              type="button"
              onClick={toggleCityDropdown}
              className="border p-2 w-full rounded-3xl text-center bg-gradient-to-r from-[#72BF44] to-[#003C10] text-white h-10 flex items-center justify-center"
            >
              <span className="ml-2">&#9660;</span>
              {userData.city || 'Wybierz miasto'}
            </button>
            {isCityDropdownOpen && (
              <ul className="absolute left-0 right-0 mt-2 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto">
                {loadingCities ? (
                  <li className="px-4 py-2 text-gray-500">Ładowanie...</li>
                ) : cityError ? (
                  <li className="px-4 py-2 text-red-500">{cityError}</li>
                ) : (
                  cities.map((city, index) => (
                    <li
                      key={index}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleCityChange(city)}
                    >
                      {city.name}
                    </li>
                  ))
                )}
              </ul>
            )}
          </div>

 {/* Płeć */}
 <div className="mb-6">
            <label className="block text-secondary font-semibold mb-2">Płeć</label>
            <div className="flex space-x-4">
              <button
                className={`px-4 py-2 rounded-full border ${
                  userData.gender === 'Kobieta' ? 'bg-[#72BF44] text-white' : 'text-gray-500'
                }`}
                onClick={() => setUserData({ ...userData, gender: 'Kobieta' })}
              >
                Kobieta
              </button>
              <button
                className={`px-4 py-2 rounded-full border ${
                  userData.gender === 'Mężczyzna' ? 'bg-green-500 text-white' : 'text-gray-500'
                }`}
                onClick={() => setUserData({ ...userData, gender: 'Mężczyzna' })}
              >
                Mężczyzna
              </button>
            </div>
          </div>

          {/* Kod QR */}
          <div className="mt-6 flex flex-col items-center">
            <img
              src="https://via.placeholder.com/100"
              alt="QR Code"
              className="w-20 h-20 rounded-full mb-2"
            />
            <p className="text-xs text-gray-500">ID: {userData.userId}</p>
          </div>
        </form>
      </div>

            {/* Right Side - Sports Interests */}
            <div className="w-2/5 pt-20">
        <p className="text-sm text-gray-500 mb-4 inline-block">
          Wybierz sport, który Cię interesuje, aby otrzymywać informacje o rywalizacjach, konkursach oraz grach miejskich dostępnych dla danej kategorii.
        </p>
           {/* Sports Grid */}
           <div className="grid grid-cols-3 gap-4 mb-6 mt-6">
            {loadingSports ? (
              <p className="text-gray-500">Ładowanie sportów...</p>
            ) : sportsError ? (
              <p className="text-red-500">{sportsError}</p>
            ) : (
              sports.map((sport) => (
                <label key={sport.id} className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    name={sport.name.toLowerCase()}
                    checked={userData.sports[sport.name.toLowerCase()] || false}
                    onChange={handleSportsChange}
                    className="hidden"
                  />
                  <span
                    className={`w-8 h-8 mr-2 border-2 border-gray-300 rounded flex items-center justify-center ${
                      userData.sports[sport.name.toLowerCase()] ? 'bg-[#72BF44]' : 'bg-white'
                    }`}
                  >
                    {userData.sports[sport.name.toLowerCase()] && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </span>
                  {sport.name}
                </label>
              ))
            )}
          </div>
      {/* Toggle Options */}
      <div className="space-y-4">
        {/* Accept Terms */}
        <div className="flex items-center">
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              name="acceptTerms"
              checked={userData.acceptTerms}
              onChange={handleToggleChange}
              className="sr-only peer"
            />
            <div className="w-14 h-8 mr-4 bg-primary rounded-full peer dark:bg-[#EBEBEB] peer-checked:bg-[#B3CDA5] transition-all duration-300"></div>
            <span className="absolute top-[4px] left-[2px] w-6 h-6 bg-white border border-gray-300 rounded-full peer-checked:left-7 transition-all duration-300"></span>
          </label>
          <span>Akceptuję regulamin</span>
          </div>

              {/* Accept Competition Terms */}
              <div className="flex items-center">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowMarketing"
                checked={userData.allowMarketing}
                onChange={handleToggleChange}
                className="sr-only peer"
                />
              <div className="w-14 h-8 mr-4 bg-primary rounded-full peer dark:bg-[#EBEBEB] peer-checked:bg-[#B3CDA5] transition-all duration-300"></div>
              <span className="absolute top-[4px] left-[2px] w-6 h-6 bg-white border border-gray-300 rounded-full peer-checked:left-7 transition-all duration-300"></span>
            </label>
                <span>Akceptuję regulamin rywalizacji</span>
          </div>
                  <span className='inline-block text-sm text-[#B7B7B7]'>Zaznacz tę opcję, jeśli chcesz aby Twoje imię i nazwisko było widoczne w rankingu rywalizacji i wyszukiwarce znajomych. Odznacz tę opcję, jeśli chcesz, aby Twoje dan były  ukryte dla wszystkich użytkowników aplikacji.</span>

          {/* Allow Display of Personal Data */}
          <div className="flex items-center justify-between">
            <span>Zezwalam na wyświetlanie moich danych osobowych innym użytkownikom</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowLocation"
                checked={userData.allowLocation}
                onChange={handleToggleChange}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-400 rounded-full peer dark:bg-gray-300 peer-checked:bg-green-500 transition-all duration-300"></div>
              <span className="absolute top-[2px] left-[2px] w-5 h-5 bg-white border border-gray-300 rounded-full peer-checked:left-6 transition-all duration-300"></span>
            </label>
          </div>

          {/* Allow Friends to See Location */}
          <div className="flex items-center justify-between">
            <span>Pozwól znajomym widzieć moją lokalizację</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowLocation"
                checked={userData.allowLocation}
                onChange={handleToggleChange}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-400 rounded-full peer dark:bg-gray-300 peer-checked:bg-green-500 transition-all duration-300"></div>
              <span className="absolute top-[2px] left-[2px] w-5 h-5 bg-white border border-gray-300 rounded-full peer-checked:left-6 transition-all duration-300"></span>
            </label>
          </div>
        </div>
        {/* Buttons */}
        <div className="mt-6 space-y-2">
          <button className="bg-green-600 text-white py-3 px-4 rounded-full w-full hover:bg-green-500 transition-colors">
            Akceptuję
          </button>
          <button className="bg-gray-200 text-gray-800 py-3 px-4 rounded-full w-full hover:bg-gray-300 transition-colors">
            Zmień hasło
          </button>
          <button className="bg-red-600 text-white py-3 px-4 rounded-full w-full hover:bg-red-500 transition-colors">
            Usuń konto
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
