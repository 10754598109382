import React from "react";
import "./AktywneIconSection.css";
import {ReactComponent as Icon1} from "../assets/icons/puchar.svg";
import {ReactComponent as Icon2} from "../assets/icons/order.svg";
import {ReactComponent as Icon3} from "../assets/icons/podium.svg";
import '../i18n';
import { useTranslation } from 'react-i18next';

function AktywneIconSection() {

    const { t } = useTranslation();

    return (
        <div className="aktywne-icon-section">
            <div className="columns-container">
                <div className="column">
                    <div className="icon">
                        <Icon1 />
                    </div>
                    <div className="text">{t('icon_section.RYWALIZACJA')}</div>
                </div>
                <div className="column">
                    <div className="icon">
                        <Icon2 />
                    </div>
                    <div className="text">{t('icon_section.GRYWALIZACJA')}</div>
                </div>
                <div className="column">
                    <div className="icon">
                        <Icon3 />
                    </div>
                    <div className="text">{t('icon_section.NAGRODY')}</div>
                </div>
            </div>
        </div>
    );
}

export default AktywneIconSection;