import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import enTranslations from "./locales/en/translation.json";
import plTranslations from "./locales/pl/translation.json";

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: enTranslations
      },
      pl: {
        translation: plTranslations
      }
    },
    lng: "pl",
    fallbackLng: "pl",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;