import React from 'react';

const AuthLayout = ({ children }) => {
  return (
    <div className="flex-col justify-center items-center min-h-auto p-6">
      <div className="mx-auto text-center pb-6">
        <img src="/images/user_account/logo.svg" alt="Logo" className="w-60 mx-auto" />
      </div>
      <div className="w-full mx-auto p-8">
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
