import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faChevronDown } from '@fortawesome/free-solid-svg-icons';

function FilterBar({ showFilterBar = true, showActivityFilter = true, showTimeFilter = true, onActivitySelect, onTimeSelect }) {
  const [showActivityDropdown, setShowActivityDropdown] = useState(false);
  const [showTimeDropdown, setShowTimeDropdown] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState('Wszystkie aktywności');
  const [selectedTimeRange, setSelectedTimeRange] = useState('Przedział czasowy');
  const [activityTypes, setActivityTypes] = useState([{ name: 'Wszystkie aktywności', id: null }]);

  // Fetch activity types from API
  useEffect(() => {
    const fetchActivityTypes = async () => {
      try {
        const response = await fetch('https://146.59.55.173/api/sports/');
        const data = await response.json();
        setActivityTypes([{ name: 'Wszystkie aktywności', id: null }, ...data.results]);

        // Log the IDs of the disciplines
        console.log('Fetched Activity Types:', data.results.map((activity) => activity.id));
      } catch (error) {
        console.error('Error fetching activity types:', error);
      }
    };

    fetchActivityTypes();
  }, []); // Empty dependency array ensures this effect runs only once

  const handleActivitySelect = (activity) => {
    setSelectedActivity(activity.name);
    setShowActivityDropdown(false);

    // Log the ID of the selected activity
    console.log('Selected Activity ID:', activity.id);

    if (onActivitySelect) onActivitySelect(activity.id); // Pass the activity ID to the parent
  };

  const handleTimeSelect = (timeRange) => {
    setSelectedTimeRange(timeRange);
    setShowTimeDropdown(false);
    if (onTimeSelect) onTimeSelect(timeRange); // Pass the time range to the parent
  };

  if (!showFilterBar) return null; // Make sure the component returns null correctly

  return (
<div className="flex items-center h-[88px] justify-center bg-white px-6 space-x-6 relative shado-bottom-gradient">
      {/* Filter Icon and Text */}
      <div className="flex items-center space-x-2 text-gray-600">
        <span className="text-lg font-medium">Filtruj</span>
        <FontAwesomeIcon icon={faFilter} className="text-lg" />
      </div>

      {/* Dropdowns */}
      <div className="flex space-x-10 text-gray-700">
        {showActivityFilter && (
          <div className="relative">
            <button
              className="flex items-center text-lg font-normal"
              onClick={() => setShowActivityDropdown(!showActivityDropdown)}
            >
              {selectedActivity}
              <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-sm" />
            </button>
            {showActivityDropdown && (
              <ul className="absolute top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-lg z-10">
                {activityTypes.map((activity) => (
                  <li
                    key={activity.id || 'all'} // Use 'all' as a key for "Wszystkie aktywności"
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleActivitySelect(activity)}
                  >
                    {activity.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        {showTimeFilter && (
          <div className="relative">
            <button
              className="flex items-center text-lg font-normal"
              onClick={() => setShowTimeDropdown(!showTimeDropdown)}
            >
              {selectedTimeRange}
              <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-sm" />
            </button>
            {showTimeDropdown && (
              <ul className="absolute top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-lg z-10">
                <li className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleTimeSelect('Dzisiaj')}>
                  Dzisiaj
                </li>
                <li className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleTimeSelect('W tym tygodniu')}>
                  W tym tygodniu
                </li>
                <li className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleTimeSelect('Ten miesiąc')}>
                  Ten miesiąc
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterBar;
