import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { FaCity } from 'react-icons/fa';
import apolloClient from '../../../queries/ApolloUser';

const BASE_URL = 'http://panel.aktywne.miasta.pl/media/';

const GET_CITY_RANKING = gql`
  query CityRanking($filter: FatAggFilter, $first: Int = 50, $offset: Int = 0, $orderBy: [FatAggsOrderBy!]) {
    allFatAggs(orderBy: $orderBy, filter: $filter, first: $first, offset: $offset) {
      nodes {
        cityGrp
        cityGrpName
        cityGrpImage
        usrFirstName
        usrLastName
        distance
        points
      }
    }
  }
`;

const Ranking = ({ competitionId }) => {
  const [rankingType, setRankingType] = useState('group');
  const [rankingData, setRankingData] = useState([]);
  const [cachedData, setCachedData] = useState({ group: null, individual: null });

  const filter = rankingType === 'group'
    ? {
      competition: { equalTo: competitionId },
      cityGrpName: { isNull: false },
      city: { isNull: true },
      grp: { isNull: true },
      subGrp: { isNull: true },
      usr: { isNull: true },
      age: { isNull: true },
      sex: { isNull: true },
      }
    : {
      competition: { equalTo: competitionId },
      cityGrpName: { isNull: true },
      city: { isNull: true },
      grp: { isNull: true },
      subGrp: { isNull: true },
      usr: { isNull: false },
      age: { isNull: true },
      sex: { isNull: true },
      };

  const orderBy = rankingType === 'group' ? ['POINTS_DESC'] : ['DISTANCE_DESC'];

  const { data, loading, error } = useQuery(GET_CITY_RANKING, {
    client: apolloClient,
    variables: { filter, orderBy, first: 100, offset: 0 }, // Możesz zwiększyć wartość na 200 lub więcej
    fetchPolicy: 'no-cache',
    skip: cachedData[rankingType] !== null,
  });
  
  

  useEffect(() => {
    if (data?.allFatAggs?.nodes) {
      // Usuń deduplikację danych
      const newData = data.allFatAggs.nodes.map((node, index) => ({
        cityId: node.cityGrp,
        cityName: rankingType === 'group'
          ? node.cityGrpName
          : `${node.usrFirstName} ${node.usrLastName}`,
        cityImgUrl: rankingType === 'group' && node.cityGrpImage
          ? `${BASE_URL}${node.cityGrpImage}`
          : null,
        distance: node.distance ? node.distance.toFixed(2) : '0',
        points: node.points ? node.points.toFixed(2) : '0',
        rank: index + 1,
      }));
  
      // Cache the new data
      setCachedData((prevCache) => ({ ...prevCache, [rankingType]: newData }));
      setRankingData(newData);
    }
  }, [data, rankingType]);
  

  useEffect(() => {
    // Use cached data if available
    if (cachedData[rankingType]) {
      setRankingData(cachedData[rankingType]);
    }
  }, [rankingType, cachedData]);

  const toggleRankingType = () => {
    setRankingType((prevType) => (prevType === 'group' ? 'individual' : 'group'));
  };

  if (loading && !cachedData[rankingType]) return <p>Ładowanie rankingu...</p>;
  if (error) return <p>Błąd ładowania danych: {error.message}</p>;

  return (
    <div className="p-6 bg-white mx-auto">
      <h2 className="text-center text-2xl font-bold text-green-700 mb-8">
        {rankingType === 'group' ? 'Ranking Grupowy' : 'Ranking Indywidualny'}
      </h2>

      <div className="text-center mb-6">
        <button
          onClick={toggleRankingType}
          className="bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-500 transition"
        >
          Przełącz na {rankingType === 'group' ? 'Indywidualny' : 'Grupowy'}
        </button>
      </div>

      {/* Top 3 Entries */}
      <div className="flex justify-center items-end my-20">
        {rankingData.slice(0, 3).map((entry, index) => {
          const isFirst = index === 0;
          const positionStyle = isFirst ? 'order-2' : index === 1 ? 'order-1' : 'order-3';
          const svgSource = `${process.env.PUBLIC_URL}/images/user_account/${isFirst ? 'first' : index === 1 ? 'second' : 'third'}_place.svg`;
          const height = isFirst ? 327 : index === 1 ? 270 : 229;

          return (
            <div
              key={entry.rank}
              className={`flex flex-col items-center justify-end p-4 mx-2 ${positionStyle}`}
              style={{
                height: `${height}px`,
                width: '178px',
                backgroundImage: `url(${svgSource})`,
                position: 'relative',
              }}
            >
              <div
                className="flex items-center justify-center bg-white rounded-full shadow-custom mb-2"
                style={{
                  width: '120px',
                  height: '120px',
                  position: 'absolute',
                  top: '-35px',
                }}
              >
                {entry.cityImgUrl ? (
                  <img src={entry.cityImgUrl} alt={entry.cityName} className="w-3/4" />
                ) : (
                  <FaCity size={24} className="text-green-600" />
                )}
              </div>
              <p className="text-white font-semibold">{entry.cityName}</p>
              <p className="text-white mt-2">
                {rankingType === 'group' ? `${entry.points} pkt` : `${entry.distance} km`}
              </p>
              {rankingType === 'group' && (
                <p className="text-white mt-1">{entry.distance} km</p>
              )}
            </div>
          );
        })}
      </div>

      {/* Full Ranking */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-4">
        {rankingData.slice(3).map((entry) => (
          <div
            key={entry.rank}
            className="flex items-center justify-between sm:flex-col md:flex-row shadow-custom rounded-3xl p-4"
          >
            <div className="flex items-center">
              <div className="p-2 mr-4 w-24 h-24 flex items-center justify-center overflow-hidden">
                {entry.cityImgUrl ? (
                  <img
                    src={entry.cityImgUrl}
                    alt={entry.cityName}
                    className="object-contain max-w-full max-h-full"
                  />
                ) : (
                  <FaCity className="text-primary-dark text-4xl" />
                )}
              </div>
              <div>
                <p className="font-semibold">{entry.cityName}</p>
              </div>
            </div>
            <div className="text-right">
              <p className="font-bold text-gray-800">
                {rankingType === 'group' ? `${entry.points} pkt` : `${entry.distance} km`}
              </p>
              {rankingType === 'group' && (
                <p className="text-sm text-gray-500">{entry.distance} km</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ranking;
