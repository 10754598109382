import React from "react";
import "./AktywneCitySection.css";
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import apolloClient from '../queries/Apollo';
import { GET_PARTNER_CITY } from '../queries/GraphQL_Query';
import CustomSlider from "./features/CustomSlider";
import LoadingIndicator from './LoadingIndicator';
import '../i18n';
import { useTranslation } from 'react-i18next';

function AktywneCitySection() {

    const { t } = useTranslation();
    const currentDate = new Date().toISOString().split('T')[0];
    const { loading: partnerLoading, error: partnerError, data: partnerData } = useQuery(GET_PARTNER_CITY, {
      variables: { greaterThan: currentDate },
      client: apolloClient,
    });

    if (partnerLoading) return <LoadingIndicator />;
    if (partnerError) return `Error! ${partnerError.message}`;

    const cities = partnerData ? partnerData.allPagePartners.nodes.map(node => node.name) : [];

    return (
        <div className="aktywne-city-section">
            <h1>{t('city_section.samorzady_wspolpracujace')}</h1>
            <div className="columns-container">
                <CustomSlider>
                    {cities.map(city => {
                        const cityData = partnerData.allPagePartners.nodes.find(node => node.name === city);
                        const cityLogoPath = cityData.logo ? `https://aktywne.miasta.pl/media/${cityData.logo}` : null;

                        if (!cityLogoPath) {
                            return null;
                        }

                        return (
                        <div className="column" key={city}>
                            <div className="icon">
                                <img src={cityLogoPath} alt={city}  />
                                <div className="stext">{city}</div>
                            </div>
                        </div>
                        );
                    }).filter(Boolean)}
                </CustomSlider>
            </div>

            <div className="rsp-container">
                <h1>{t('city_section.poznaj_miasta_biorace_udzial_w_RSP')}</h1>
                <Link to="/map" className="custom-icon">
                <img src={process.env.PUBLIC_URL + '/images/miasta.png'} alt="Mapa" />
                </Link>
            </div>
        </div>
    );
}

export default AktywneCitySection;