
import React from "react";
import './HelpSection.css';
import HelpSectionItem from "./HelpSectionItem";
import Pagination from "./features/Pagination";
import { useQuery } from '@apollo/client';
import apolloClient from '../queries/Apollo';
import { GET_HELP } from '../queries/GraphQL_Query';
import { useState } from "react";
import '../i18n';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from './LoadingIndicator';

function HelpSection() {

    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15; // Ilość elementów na stronę

    const { loading, error, data } = useQuery(GET_HELP, {
        client: apolloClient,
    });

    // Logika do zmiany strony
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) return <LoadingIndicator />;
    if (error) return <p>Error: {error.message}</p>;

    // Obliczanie indeksów dla bieżącej strony
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data?.allPageHelpdesks?.nodes.slice(indexOfFirstItem, indexOfLastItem);

    const totalItems = data?.allPageHelpdesks?.totalCount;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        // <div className="container" >
            <div className="help">
                <h1>{t('help_section.FAQ')}</h1>
                <div className="section">
                    {currentItems.map((item, index) => (
                    <HelpSectionItem key={item.id} index={index + 1 + indexOfFirstItem} {...item} />
                    ))}
                </div>
                <div className="pagination-container">
                    <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
                </div>
            </div>
        // </div>
    );
}

export default HelpSection;