import React from 'react';
import './Footer.css';
// // import footerLogo from '../assets/icons/footer_logo.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
import '../i18n';
import { useTranslation } from 'react-i18next';

function Footer() {

    const { t } = useTranslation();

    return (
        <footer className="footer" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/stopka.png)` }}>
            <div className="footer-container-logo">
                <img src={process.env.PUBLIC_URL + '/images/footer_logo.png'} alt="FooterLogo" className='footer-logo' />
            </div>

            {/* <div style={{maxWidth: '1000px', margin: '60px auto'}}> */}
                <div className="contact-container" style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className="contact-column">
                        <h3>{t('footer.Media_społecznościowe')}</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <a href="https://www.facebook.com/aktywnemiastapl/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '20px' }}>
                                <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: 'white' }}/>
                            </a>
                            <a href="https://www.youtube.com/channel/UCg5q4GLYXbtl6Y37v7MYHGQ" target="_blank" rel="noopener noreferrer" style={{ marginRight: '20px' }}>
                                <FontAwesomeIcon icon={faYoutube} size="2x" style={{ color: 'white' }}/>
                            </a>
                            <a href="https://www.instagram.com/aktywnemiasta.pl/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '20px' }}>
                                <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: 'white' }}/>
                            </a>
                        </div>
                    </div>
                    <div className="contact-column" style={{ textAlign: 'left'}}>
                        <h3>{t('footer.Kontakt')}</h3>
                        <a href="mailto:kontakt@aktywne.miasta.pl" style={{ display: 'block', textDecoration: 'none', color: 'inherit', cursor: 'pointer'}}>{t('footer.e-mail')}kontakt@aktywne.miasta.pl</a>
                        <a href="tel:525858913" style={{ display: 'inline', textDecoration: 'none', color: 'inherit' }}>{t('footer.tel')}52 58 58 913</a>
                        <a href="tel:525858446" style={{ display: 'inline', textDecoration: 'none', color: 'inherit' }}> / 52 58 58 446</a>
                        <a href="http://www.bydgoszcz.pl" style={{ display: 'block', textDecoration: 'none', color: 'inherit' }}>{t('footer.strona')}www.bydgoszcz.pl</a>
                    </div>
                    
                </div>
            {/* </div> */}
            <div className="contact-column" style={{marginBottom: '50px', display: 'flex', justifyContent: 'center' }}>
                <p style={{ textDecoration: 'none', color: 'inherit' }}>
                    <span style={{ fontWeight: 'bold' }}>{t('footer.Adres')}: </span> 
                    {t('footer.Urząd_Miasta_Bydgoszczy')} {t('footer.ul')} Jezuicka 1, 85-102 Bydgoszcz
                </p>
            </div>

            
            
            <div className="footer-info">
                <p style={{ marginLeft:'10px', marginRight: '10px' }}>
                    <Link to="/privacy-policy/3" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>{t('footer.Polityka_Prywatności')}</Link> | 
                    Copyright: AKTYWNE MIASTA | <a href="http://www.rosmedia.pl" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Powered by ROSMEDIA</a> | <Link to="/privacy-policy/4" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>{t('footer.Deklaracja_Dostępności')}</Link> | <Link to="/regulamin-aplikacji/6" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>{t('footer.Regulamin_aplikacji')}</Link>
                </p>
            </div>
        </footer>
    );
}

export default Footer;
