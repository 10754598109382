import React from "react";
import "./AktywneAppSection.css";
import {ReactComponent as IconsiOS} from "../assets/icons/ios.svg";
import {ReactComponent as IconsAndroid} from "../assets/icons/android.svg";
import IconGarmin from  "../assets/icons/garmin.png";
import '../i18n';
import { useTranslation } from 'react-i18next';

function AktywneAppSection() {

    const { t } = useTranslation();

    return (
        <div className="aktywne-app-section">
            <div className="column-container">
                <div className="column">
                    <div className="column column-image">
                        <img src={process.env.PUBLIC_URL + '/images/phones.png'} alt="Phones" />
                    </div>
                </div>
                <div className="column">
                    <div className="column column-content">
                        <h1>{t('city_section.aplikacja_aktywne_miasta')}</h1>
                        <p>{t('city_section.aplikacja_opis')}</p>
                        <div className="buttons">
                            <a href="https://play.google.com/store/apps/details?id=pl.bydgoszcz.aktywna&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                                <button>
                                    <IconsAndroid />
                                </button>
                            </a>
                            <a href="https://apps.apple.com/us/app/aktywne-miasta/id1317715080" target="_blank" rel="noopener noreferrer">
                                <button>
                                    <IconsiOS />
                                </button>
                            </a>
                            <img src={IconGarmin} alt="Garmin" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default AktywneAppSection;