
import React from "react";
import "./CustomSlider.css";
import { useState, useEffect } from "react";

function CustomSlider({ children }) {
    const [currentSlide, setCurrentSlide] = useState(0);

    // Przygotowanie slajdów do wyświetlenia
    const slidesToShow = 4;
    const totalSlides = children.length;
    const slides = [];

    const sekundy = children.length > slidesToShow ? 5000 : 500000000;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide(prevSlide => (prevSlide + 1) % children.length);
        }, sekundy); // Przewijanie co x sekundy

        return () => clearInterval(interval);
    }, [sekundy, children.length]);

    for (let i = 0; i < slidesToShow; i++) {
        slides.push(children[(currentSlide + i) % totalSlides]);
    }

    return (
        <div className="custom-slider">
            <div className="slider-content">
                {slides.map((slide, index) => (
                    <div className="slide" key={index}>
                        {slide}
                    </div>
                ))}
            </div>
            {/* Możesz dodać przyciski do ręcznego przewijania */}
        </div>
    );
}

export default CustomSlider;