import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../components/UserContext';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login-lite';

function LoginPage() {
  const [login, setLogin] = useState('');
  const [pass, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { saveUserData } = useUserContext();

  const handleLoginChange = (e) => setLogin(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const formData = new FormData();
      formData.append('login', login);
      formData.append('pass', pass);
      formData.append('registration_id', 'xxx');

      const response = await fetch('https://aplikacja.aktywna.bydgoszcz.pl/api/user_login', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.mess?.[0] || 'Błędne dane logowania');
      }

      const data = await response.json();
      if (data.result === 'success' && data.data && data.data.id) {
        saveUserData(data.data);
        navigate('/account/activities');
      } else {
        throw new Error('Nie udało się pobrać ID użytkownika.');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    const token = credentialResponse.credential;
    fetch('https://aplikacja.aktywna.bydgoszcz.pl/api/google_login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 'success') {
          saveUserData(data.data);
          navigate('/account/activities');
        } else {
          setError('Błąd logowania przez Google');
        }
      })
      .catch((error) => setError(error.message));
  };

  const handleFacebookResponse = (response) => {
    if (response.accessToken) {
      fetch('https://aplikacja.aktywna.bydgoszcz.pl/api/facebook_login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ accessToken: response.accessToken }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.result === 'success') {
            saveUserData(data.data);
            navigate('/account/activities');
          } else {
            setError('Błąd logowania przez Facebook');
          }
        })
        .catch((error) => setError(error.message));
    }
  };

  return (
    <GoogleOAuthProvider clientId="396321888808-dupm9jocnolthfm4964l2dl89qkk3seg.apps.googleusercontent.com">
      <div className="flex justify-center mx-auto w-1/2 items-center p-6 rounded-lg shadow-custom">
        <div className="w-full max-w-md p-8">
          <h2 className="text-3xl font-bold text-[#016623] mb-6 text-center">Logowanie</h2>
          {error && <p className="text-red-600 mb-4 text-center">{error}</p>}
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">Login</label>
              <input
                type="text"
                id="username"
                value={login}
                onChange={handleLoginChange}
                className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#016623] focus:border-[#016623]"
                placeholder="Wprowadź login"
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Hasło</label>
              <input
                type="password"
                id="password"
                value={pass}
                onChange={handlePasswordChange}
                className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#016623] focus:border-[#016623]"
                placeholder="Wprowadź hasło"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 bg-[#016623] text-white font-semibold rounded-md hover:bg-[#004d2b] transition duration-200"
            >
              Zaloguj
            </button>
            <div className="flex justify-between text-sm mt-4">
              <button
                type="button"
                onClick={() => navigate('/account/register')}
                className="text-[#016623] hover:underline"
              >
                Zarejestruj się
              </button>
              <button
                type="button"
                onClick={() => navigate('/forgot-password')}
                className="text-[#016623] hover:underline"
              >
                Przypomnij hasło
              </button>
            </div>
          </form>

          <div className="flex flex-col space-y-4 mt-6">
            {/* <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={() => setError('Błąd logowania przez Google')}
              render={(renderProps) => (
                <button onClick={renderProps.onClick} className="w-full py-2 bg-red-500 text-white font-semibold rounded-md hover:bg-red-700 transition duration-200 flex justify-center items-center">
                  <span className="material-icons mr-2">account_circle</span>
                  Zaloguj przez Google
                </button>
              )}
            />
            <FacebookLogin
              appId="2080373255312950"
              autoLoad={false}
              callback={handleFacebookResponse}
              render={(renderProps) => (
                <button onClick={renderProps.onClick} className="w-full py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-800 transition duration-200 flex justify-center items-center">
                  <span className="material-icons mr-2">facebook</span>
                  Zaloguj przez Facebook
                </button>
              )}
            /> */}
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default LoginPage;
