// MapSectionn

import React, { useState, useEffect } from "react";
import "./MapSection.css";
import "./features/ComboBox.css"
import MojaMapa from "./features/MojaMapa"
import { useQuery } from '@apollo/client';
import { GET_DISCRITS } from '../queries/GraphQL_Query';
import { GET_PARTNER_CITY } from'../queries/GraphQL_Query';
import apolloClient from '../queries/Apollo';
import Modal from 'react-modal';
import parseHTML from 'html-react-parser';
import { marked } from 'marked';
import SearchBar from "./features/SearchBar";
import '../i18n';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from './LoadingIndicator';

Modal.setAppElement('#root');

function MapSection() {

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [modalCity, setModalCity] = useState(null);

  const { loading, error, data } = useQuery(GET_DISCRITS, {
    client: apolloClient,
  });
  const currentDate = new Date().toISOString().split('T')[0];
  const { loading: partnerLoading, error: partnerError, data: partnerData } = useQuery(GET_PARTNER_CITY, {
    variables: { greaterThan: currentDate },
    client: apolloClient,
  });
  
  if (loading) return <LoadingIndicator />;
  if (error) return `Error! ${error.message}`;
  if (partnerLoading) return <LoadingIndicator />;
  if (partnerError) return `Error! ${partnerError.message}`;

  const cities = partnerData 
  ? partnerData.allPagePartners.nodes.map(node => node.name).sort() 
  : [];
  const discrits = data.allActivitiesVoivodeships.nodes.map(node => node.name).sort();
  const selectedCityData = partnerData.allPagePartners.nodes.find(node => node.name === modalCity);

  const handleRegionClick = (region) => {
    setSelectedRegion(region);
  };

  const handleCityClick = (city) => {
    setModalCity(city);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const description = selectedCityData ? selectedCityData.description : '';
  const html = description ? marked.parse(description) : '';

  const regionsData = partnerData.allPagePartners.nodes.reduce((acc, node) => {
    const regionName = node.activitiesCitygroupByCityGroupId.activitiesVoivodeshipByVoivodeshipId.name;
    if (!acc[regionName]) {
      acc[regionName] = 0;
    }
    acc[regionName]++;
    return acc;
  }, {});

  const filteredCities = cities.filter(city => {
    if (!selectedRegion || selectedRegion === '') {
      return true;
    }
    const cityData = partnerData.allPagePartners.nodes.find(node => node.name === city);
    const voivodeshipName = cityData.activitiesCitygroupByCityGroupId.activitiesVoivodeshipByVoivodeshipId.name;
    return voivodeshipName === selectedRegion;
  });

  const filteredDiscrits = selectedCity 
  ? [partnerData.allPagePartners.nodes.find(node => node.name === selectedCity).activitiesCitygroupByCityGroupId.activitiesVoivodeshipByVoivodeshipId.name]
  : discrits;

  return (
    <div>
      <div className="map-container">
        <h1>{t('map_section.Wyszukaj_miasto')}</h1>
        <div style={{ marginBottom: '30px', display: 'flex' }}>
          <SearchBar 
            style={{borderTopLeftRadius: '30px', borderBottomLeftRadius: '30px'}} 
            suggestions={filteredCities} 
            placeholder={t('map_section.Wybierz_miasto')}
            setSelectedCity={setSelectedCity}
            onInputChange={(event) => {
              if (event.target.value === '') {
                setSelectedCity(null);
              }
            }} 
          />
          <SearchBar 
  style={{borderTopRightRadius: '30px', borderBottomRightRadius: '30px'}} 
  suggestions={filteredDiscrits}
  placeholder={t('map_section.Wybierz_województwo')}
  setSelectedRegion={setSelectedRegion}
  selectedRegion={selectedRegion}
  selectedCity={selectedCity}
  onInputChange={(event) => {
    if (event.target.value === '') {
      setSelectedRegion(null);
    }
  }} 
  />
        </div>
      </div>
      <div className="map-container-green">
        <div className="map-container-columns-container">
          <div className="map-container-column">
            <div className="map-container-column-list">
              <ul>
              {
                cities
                .filter(city => {
                  const cityData = partnerData.allPagePartners.nodes.find(node => node.name === city);
                  const voivodeshipName = cityData.activitiesCitygroupByCityGroupId.activitiesVoivodeshipByVoivodeshipId.name;
                  return (!selectedRegion || selectedRegion === '' || voivodeshipName === selectedRegion) && (!selectedCity || selectedCity === city);
                })
                .map(city => {
                  const cityData = partnerData.allPagePartners.nodes.find(node => node.name === city);
                  const voivodeshipName = cityData.activitiesCitygroupByCityGroupId.activitiesVoivodeshipByVoivodeshipId.name;
                  const cityLogoPath = ` https://aktywne.miasta.pl/media/${cityData.logo}`;

                  return (
                    <li key={city} onClick={() => handleCityClick(city)}>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px', width: '50px', marginLeft: '10px', marginRight: '10px'}}>
                        {cityLogoPath && <img src={cityLogoPath} alt={city} style={{maxHeight: '50px', maxWidth: '50px'}} />}
                      </div>
                      <div>
                        <div style={{fontWeight: 'bold', marginBottom: '3px'}}>{city}</div>
                        <div style={{fontSize: 'small' }}>{voivodeshipName}</div>
                      </div>
                    </li>
                  );
                })
              }

              {selectedRegion && cities.every(city => {
                const cityData = partnerData.allPagePartners.nodes.find(node => node.name === city);
                const voivodeshipName = cityData.activitiesCitygroupByCityGroupId.activitiesVoivodeshipByVoivodeshipId.name;
                return voivodeshipName !== selectedRegion;
              }) && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '20px'}}>Brak wyników</div>}
              </ul>
            </div>
            <Modal
              isOpen={isPopupOpen}
              onRequestClose={handleClosePopup}
              contentLabel="Wybrane miasto"
              className="my-modal"
              overlayClassName="my-modal-overlay"
            >
              <h2>{modalCity}</h2>
              <div style={{overflowWrap: 'break-word', textAlign: 'left', lineHeight: '22px'}}>
                {parseHTML(html, { replace: ({ attribs, name }) => {
                  if (name === 'img') {
                    return (
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={`https://aktywne.miasta.pl/${attribs.src}`} alt={attribs.alt} style={{width: '100%', height: 'auto', borderRadius: '20px'}} />
                      </div>
                    );
                  }
                }})}
              </div>
              <button className="close-button" onClick={handleClosePopup}>✕</button>
            </Modal>
          </div>
          <div className="map-container-column">
            <div className="map-container-column-map">
              <MojaMapa onClick={handleRegionClick} regionsData={regionsData} selectedRegion={selectedRegion} setSelectedRegion={setSelectedRegion}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapSection;