import React from 'react';
import { Puff } from 'react-loader-spinner';

const LoadingIndicator = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }}>
    <Puff
      color="#006027"
      height={100}
      width={100}
      timeout={3000}
    />
  </div>
);

export default LoadingIndicator;