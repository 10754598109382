
import React from "react";
import ContactSection from "./ContactSection";
import { useQuery } from '@apollo/client';
import { GET_FOR_PARTNER } from '../queries/GraphQL_Query';
import apolloClient from '../queries/Apollo';
import parseHTML from 'html-react-parser';
import { marked } from 'marked';
import '../i18n';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from './LoadingIndicator';

function SamorzadSection() {

    const { t } = useTranslation();
    const { loading, error, data } = useQuery(GET_FOR_PARTNER, {
        variables: { id: '1' },
        client: apolloClient,
    });

    if (loading) return <LoadingIndicator />;
    if (error) return <p>Error: {error.message}</p>;

    const description = data.pageForpartnerById.description;

    const html = marked.parse(description);

    return (
        <div>
            <div className="content">
                <h1>{t('samorzad_section.Samorząd')}</h1>
                <div className="content-text">
                    {parseHTML(html)}
                </div>
            </div>
            <ContactSection />
        </div>
    );
}

export default SamorzadSection;