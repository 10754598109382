import React, { useState, useEffect } from 'react';
import { useUserContext } from '../components/UserContext';

const RegistrationForm = () => {
  const {
    userId,
    firstName,
    lastName,
    email,
    dateOfBirth,
    height,
    weight,
    cityId,
    preferredSports,
    sex,
    profileImage,
    showLocationToFriends,
  } = useUserContext();

  const [userData, setUserData] = useState({
    userId: userId || '',
    first_name: firstName || '',
    last_name: lastName || '',
    login: '', // New required field
    email: email || '',
    height: height || '',
    weight: weight || '',
    date_of_birth: dateOfBirth || '',
    sex: sex === '0' ? 0 : 1, // 0 for female, 1 for male
    city_id: cityId || '',
    sports: preferredSports.reduce((acc, sport) => ({ ...acc, [sport]: true }), {}),
    acceptTerms: false,
    allowMarketing: false,
    public: 1,
    show_my_location_to_friends: showLocationToFriends ? 1 : 0,
    password: '',
    confirmPassword: '',
    registration_id: 'xxx',
  });

  const [isCityDropdownOpen, setCityDropdownOpen] = useState(false);
  const [cities, setCities] = useState([]);
  const [sports, setSports] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingSports, setLoadingSports] = useState(true);
  const [cityError, setCityError] = useState(null);
  const [sportsError, setSportsError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchSports();
    fetchCities();
  }, []);

  const fetchCities = async () => {
    setLoadingCities(true);
    try {
      const allCities = [];
      let nextUrl = 'http://146.59.55.173/api/cities/';
      while (nextUrl) {
        const response = await fetch(nextUrl);
        if (!response.ok) throw new Error('Error fetching cities');
        const data = await response.json();
        allCities.push(...data.results);
        nextUrl = data.next;
      }
      setCities(allCities);
      if (cityId) {
        const userCity = allCities.find(city => city.id === parseInt(cityId, 10));
        setUserData(prevData => ({ ...prevData, city_id: userCity ? userCity.id : '' }));
      }
    } catch (error) {
      setCityError('Could not load cities');
    } finally {
      setLoadingCities(false);
    }
  };

  const fetchSports = async () => {
    setLoadingSports(true);
    try {
      const response = await fetch('https://146.59.55.173/api/sports/');
      if (!response.ok) throw new Error('Error fetching sports');
      const data = await response.json();
      setSports(data.results);
    } catch (error) {
      setSportsError('Could not load sports');
    } finally {
      setLoadingSports(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleGenderChange = (gender) => setUserData(prevData => ({ ...prevData, sex: gender === 'Kobieta' ? 0 : 1 }));

  const handleSportsChange = (e) => {
    const { name, checked } = e.target;
    setUserData(prevData => ({
      ...prevData,
      sports: { ...prevData.sports, [name]: checked },
    }));
  };

  const handleCityChange = (city) => {
    setUserData(prevData => ({ ...prevData, city_id: city.id }));
    setCityDropdownOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (userData.password !== userData.confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('first_name', userData.first_name);
      formData.append('last_name', userData.last_name);
      formData.append('login', userData.login);
      formData.append('email', userData.email);
      formData.append('pass', userData.password);
      formData.append('pass2', userData.confirmPassword);
      formData.append('weight', parseInt(userData.weight, 10));
      formData.append('height', parseInt(userData.height, 10));
      formData.append('date_of_birth', userData.date_of_birth);
      formData.append('sex', userData.sex);
      formData.append('preferred_sports', Object.keys(userData.sports).filter(key => userData.sports[key]).join(','));
      formData.append('registration_id', userData.registration_id);
      formData.append('public', userData.public ? 1 : 0);
      formData.append('full_version', 1);
      formData.append('city_id', parseInt(userData.city_id, 10));
      formData.append('show_my_location_to_friends', userData.show_my_location_to_friends);
      formData.append('log_platform', 'web');
      formData.append('log_app_version', '1.0');
      formData.append('log_device_manufacturer', 'unknown');
      formData.append('log_device_model', 'unknown');
      formData.append('log_language_code', 'pl');

      const response = await fetch('https://aplikacja.aktywna.bydgoszcz.pl/api/user_register', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (response.ok && result.result === 'success') {
        setSuccessMessage('Registration successful!');
      } else {
        setErrorMessage(result.mess?.[0] || 'Registration failed');
      }
    } catch (error) {
      setErrorMessage('An error occurred during registration');
      console.error(error);
    }
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  return (
    <div className="flex flex-col md:flex-row rounded-lg shadow-custom p-6 bg-white w-full max-w-6xl mx-auto">
      {/* Left Column */}
      <div className="w-full md:w-1/2 p-4">
        <h2 className="text-2xl font-bold text-[#016623] uppercase mb-4">Rejestracja</h2>
        <div className="flex justify-center mb-4">
          <img src={profileImage || "https://via.placeholder.com/100"} alt="User" className="w-24 h-24 rounded-full" />
        </div>
        <form className="space-y-4" onSubmit={handleSubmit}>
          {/* Input Fields */}
          <div><label className="block">Imię</label><input type="text" name="first_name" value={userData.first_name} onChange={handleChange} className="w-full border rounded-full px-4 py-2" required /></div>
          <div><label className="block">Nazwisko</label><input type="text" name="last_name" value={userData.last_name} onChange={handleChange} className="w-full border rounded-full px-4 py-2" required /></div>
          <div><label className="block">Login</label><input type="text" name="login" value={userData.login} onChange={handleChange} className="w-full border rounded-full px-4 py-2" required /></div>
          <div><label className="block">Mail</label><input type="email" name="email" value={userData.email} onChange={handleChange} className="w-full border rounded-full px-4 py-2" /></div>
          <div><label className="block">Hasło</label><input type="password" name="password" value={userData.password} onChange={handleChange} className="w-full border rounded-full px-4 py-2" required /></div>
          <div><label className="block">Powtórz hasło</label><input type="password" name="confirmPassword" value={userData.confirmPassword} onChange={handleChange} className="w-full border rounded-full px-4 py-2" required /></div>
          <div><label className="block">Wzrost</label><input type="number" name="height" value={userData.height} onChange={handleChange} className="w-full border rounded-full px-4 py-2" /></div>
          <div><label className="block">Waga</label><input type="number" name="weight" value={userData.weight} onChange={handleChange} className="w-full border rounded-full px-4 py-2" /></div>
          <div><label className="block">Data urodzenia</label><input type="date" name="date_of_birth" value={userData.date_of_birth} onChange={handleChange} className="w-full border rounded-full px-4 py-2" required /></div>

          {/* Gender Selection */}
          <div className="mb-4">
            <label className="block mb-2">Płeć</label>
            <div className="flex space-x-4">
              <button type="button" className={`w-1/2 px-4 py-2 rounded-full ${userData.sex === 0 ? 'bg-[#72BF44] text-white' : 'text-gray-500'}`} onClick={() => handleGenderChange('Kobieta')}>Kobieta</button>
              <button type="button" className={`w-1/2 px-4 py-2 rounded-full ${userData.sex === 1 ? 'bg-green-500 text-white' : 'text-gray-500'}`} onClick={() => handleGenderChange('Mężczyzna')}>Mężczyzna</button>
            </div>
          </div>

          {/* City Selection */}
          <div className="relative">
            <label className="block mb-2">Miasto</label>
            <button type="button" onClick={() => setCityDropdownOpen(!isCityDropdownOpen)} className="w-full border rounded-full px-4 py-2 bg-gradient-to-r from-[#72BF44] to-[#003C10] text-white">
              {userData.city_id ? cities.find(city => city.id === parseInt(userData.city_id, 10))?.name : 'Wybierz miasto'}
            </button>
            {isCityDropdownOpen && (
              <ul className="absolute left-0 right-0 mt-2 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto z-10">
                {loadingCities ? (
                  <li className="px-4 py-2 text-gray-500">Ładowanie...</li>
                ) : cityError ? (
                  <li className="px-4 py-2 text-red-500">{cityError}</li>
                ) : (
                  cities.map((city, index) => (
                    <li key={index} className="px-4 py-2 cursor-pointer hover:bg-gray-200" onClick={() => handleCityChange(city)}>
                      {city.name}
                    </li>
                  ))
                )}
              </ul>
            )}
          </div>
        </form>
      </div>

      {/* Right Column */}
      <div className="w-full md:w-1/2 p-4 mt-6 md:mt-0">
        <h2 className="text-lg font-semibold mb-4">Wybierz sport, który Cię interesuje...</h2>
        <div className="grid grid-cols-3 gap-4 mb-4">
          {loadingSports ? (
            <p className="text-gray-500">Ładowanie sportów...</p>
          ) : sportsError ? (
            <p className="text-red-500">{sportsError}</p>
          ) : (
            sports.map((sport) => (
              <label key={sport.id} className="flex items-center cursor-pointer">
                <input type="checkbox" name={sport.id} checked={userData.sports[sport.id] || false} onChange={handleSportsChange} className="hidden" />
                <span className={`w-8 h-8 mr-2 border-2 border-gray-300 rounded flex items-center justify-center ${userData.sports[sport.id] ? 'bg-[#72BF44]' : 'bg-white'}`}>
                  {userData.sports[sport.id] && <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a 1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clipRule="evenodd" /></svg>}
                </span>
                {sport.name}
              </label>
            ))
          )}
        </div>
     {/* Accept Competition Terms */}
     <div className="flex items-center">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowMarketing"
                checked={userData.allowMarketing}
                onChange={handleToggleChange}
                className="sr-only peer"
                />
              <div className="w-14 h-8 mr-4 bg-primary rounded-full peer dark:bg-[#EBEBEB] peer-checked:bg-[#B3CDA5] transition-all duration-300"></div>
              <span className="absolute top-[4px] left-[2px] w-6 h-6 bg-white border border-gray-300 rounded-full peer-checked:left-7 transition-all duration-300"></span>
            </label>
                <span>Akceptuję regulamin rywalizacji</span>
          </div>
                  <span className='inline-block text-sm text-[#B7B7B7]'>Zaznacz tę opcję, jeśli chcesz aby Twoje imię i nazwisko było widoczne w rankingu rywalizacji i wyszukiwarce znajomych. Odznacz tę opcję, jeśli chcesz, aby Twoje dan były  ukryte dla wszystkich użytkowników aplikacji.</span>

          {/* Allow Display of Personal Data */}
          <div className="flex items-center justify-between">
            <span>Zezwalam na wyświetlanie moich danych osobowych innym użytkownikom</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowLocation"
                checked={userData.allowLocation}
                onChange={handleToggleChange}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-400 rounded-full peer dark:bg-gray-300 peer-checked:bg-green-500 transition-all duration-300"></div>
              <span className="absolute top-[2px] left-[2px] w-5 h-5 bg-white border border-gray-300 rounded-full peer-checked:left-6 transition-all duration-300"></span>
            </label>
          </div>

          {/* Allow Friends to See Location */}
          <div className="flex items-center justify-between">
            <span>Pozwól znajomym widzieć moją lokalizację</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="allowLocation"
                checked={userData.allowLocation}
                onChange={handleToggleChange}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-400 rounded-full peer dark:bg-gray-300 peer-checked:bg-green-500 transition-all duration-300"></div>
              <span className="absolute top-[2px] left-[2px] w-5 h-5 bg-white border border-gray-300 rounded-full peer-checked:left-6 transition-all duration-300"></span>
            </label>
          </div>

        {/* Submit and Action Buttons */}
        <div className="mt-6 space-y-2">
          <button type="button" onClick={handleSubmit} className="w-full bg-green-600 text-white py-2 rounded-full">Zarejestruj</button>
          <button className="w-full bg-gray-200 text-gray-800 py-2 rounded-full">Zmień hasło</button>
          <button className="w-full bg-red-600 text-white py-2 rounded-full">Usuń konto</button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
