// logout.js (a utility function file)

import { useNavigate } from 'react-router-dom';

export const Logout = () => {
  // Remove the user data and session data from localStorage
  localStorage.removeItem('userId');
  localStorage.removeItem('sessionId');
  
  // Optionally, call your API to invalidate the session on the server
  // fetch('https://your-api-endpoint/logout', { method: 'POST' });

  // Redirect the user to the login page
  const navigate = useNavigate();
  navigate('/login');
};
