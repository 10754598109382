// SearchBar

import React, { useState, useEffect } from 'react';
import "./SearchBar.css"

const SearchBar = ({ suggestions, style, setSelectedRegion, setSelectedCity, onInputChange, placeholder, selectedRegion, selectedCity, partnerData }) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [lastSuggestionIndex, setLastSuggestionIndex] = useState(null);

  useEffect(() => {
    setSearchValue(selectedRegion || '');
  }, [selectedRegion]);
  
  const handleInputChange = (event) => {
    const searchInput = event.target.value;
    setSearchValue(searchInput);
    if (searchInput.length > 0) {
      let filtered;
      if (selectedCity) {
        if (partnerData && partnerData.allPagePartners && partnerData.allPagePartners.nodes) {
          const cityData = partnerData.allPagePartners.nodes.find(node => node.name === selectedCity);
  
          if (cityData && cityData.activitiesCitygroupByCityGroupId && cityData.activitiesCitygroupByCityGroupId.activitiesVoivodeshipByVoivodeshipId) {
            const voivodeshipName = cityData.activitiesCitygroupByCityGroupId.activitiesVoivodeshipByVoivodeshipId.name;
            filtered = [voivodeshipName];
          }
        }
      } else {
        filtered = suggestions.filter(suggestion =>
          suggestion.toLowerCase().includes(searchInput.toLowerCase())
        );
      }
      setFilteredSuggestions(filtered);
      setLastSuggestionIndex(filtered.length - 1);
    } else {
      setFilteredSuggestions(suggestions);
      setLastSuggestionIndex(suggestions.length - 1);
    }
    setIsDropdownVisible(true);
    if (onInputChange) {
      onInputChange(event);
    }
  };
  
  const handleSuggestionClick = (suggestion) => {
    setSearchValue(suggestion);
    if (setSelectedRegion) {
      setSelectedRegion(suggestion);
    }
    if (setSelectedCity) {
      setSelectedCity(suggestion);
    }
    setFilteredSuggestions([]);
    setIsDropdownVisible(false);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsDropdownVisible(false);
    }, 200);
  };

  const handleFocus = () => {
    if (searchValue.length === 0) {
      setFilteredSuggestions(suggestions);
      setLastSuggestionIndex(suggestions.length - 1);
    }
    setIsDropdownVisible(true);
  };

  return (
    <div className='search-bar-container' style={{ position: 'relative' }}>
      <input
        type="text"
        value={searchValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        style={style}
        placeholder={placeholder}
      />
      {isDropdownVisible && filteredSuggestions.length > 0 && (
        <div className='suggestions-dropdown'>
          {filteredSuggestions.map((suggestion, index) => (
            <div 
              key={index} 
              onClick={() => handleSuggestionClick(suggestion)} 
              style={{ 
                cursor: 'pointer', 
                borderBottom: index === lastSuggestionIndex ? 'none' : '1px solid gray' 
              }}
            >
              {suggestion}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;