
import React from "react";
import ContactSection from "./ContactSection";
import { useQuery } from '@apollo/client';
import { GET_FOR_PARTNER } from '../queries/GraphQL_Query';
import apolloClient from '../queries/Apollo';
import parseHTML from 'html-react-parser';
import { marked } from 'marked';
import '../i18n';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from './LoadingIndicator';
import Icon1 from "../assets/icons/sponsors/city.png";
import Icon2 from "../assets/icons/sponsors/onet.png";
import Icon3 from "../assets/icons/sponsors/zmp.png";
import Icon4 from "../assets/icons/sponsors/sisu1.png";
import Icon5 from "../assets/icons/sponsors/metropolie.png";
import Icon6 from "../assets/icons/sponsors/eska.png";
import Icon7 from "../assets/icons/sponsors/ibombo.png";
import Icon8 from "../assets/icons/sponsors/medonet.png";
import Icon9 from "../assets/icons/sponsors/noizz.png";
import Icon10 from "../assets/icons/sponsors/ecocleanBiznes.png";
import Icon11 from "../assets/icons/sponsors/peterson_tacx.png";
import IconSponsor1 from "../assets/icons/sponsors/centrum.png";
import IconSponsor2 from "../assets/icons/sponsors/fale.png";

function BiznesSection() {

    const { t } = useTranslation();
    const { loading, error, data } = useQuery(GET_FOR_PARTNER, {
        variables: { id: '2' },
        client: apolloClient,
    });

    const icons = [
        { link: "https://centrumrowerowe.pl/", src: IconSponsor1, alt: 'IconSponsor1' },
        { link: "https://falelokikoki.pl/", src: IconSponsor2, alt: 'IconSponsor2' },
        { link: "https://www.city-hotel.pl", src: Icon1, alt: 'Icon1' },
        { link: "https://www.onet.pl", src: Icon2, alt: 'Icon2' },
        { link: "https://www.miasta.pl/", src: Icon3, alt: 'Icon3' },
        { link: "https://sisusport.com", src: Icon4, alt: 'Icon4' },
        { link: "https://www.metropoliabydgoszcz.pl", src: Icon5, alt: 'Icon5' },
        { link: "https://www.eska.pl", src: Icon6, alt: 'Icon6' },
        { link: "https://www.ibombo.com", src: Icon7, alt: 'Icon7' },
        { link: "https://www.medonet.pl", src: Icon8, alt: 'Icon8' },
        { link: "https://noizz.pl", src: Icon9, alt: 'Icon9' },
        { link: "https://www.facebook.com/people/Ecoclean-bikecom/61559956842976", src: Icon10, alt: 'Icon10' },
        { link: "https://www.bidonypro.pl", src: Icon11, alt: 'Icon11' },

    ];

    if (loading) return <LoadingIndicator />;
    if (error) return <p>Error: {error.message}</p>;

    const description = data.pageForpartnerById.description;

    const html = marked.parse(description);

    return (
        <div>
            <div className="content">
                <h1>{t('biznes_section.Biznes')}</h1>
                <div className="content-text">
                    {parseHTML(html)}
                </div>
            </div>
            <div className="icons-container">
                <h2 className="icons-title">Współpracują z nami:</h2>
                {icons.map((icon, index) => (
                    <div key={index} className="photo-container">
                        <a href={icon.link} target="_blank" rel="noopener noreferrer">
                            <img src={icon.src} alt={icon.alt} className="icon-image" />
                        </a>
                    </div>
                ))}
            </div>
            <ContactSection />

            <style jsx>{`
                .icons-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 20px;
                    max-width: 1000px;
                    margin: auto;
                    padding-bottom: 20px;
                }
                .icons-title {
                    width: 100%;
                    text-align: center;
                    color: #494A49;
                }
                .photo-container {
                    width: calc(15% - 20px);
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .icon-image {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
                @media (max-width: 600px) {
                    .photo-container {
                        width: 100px;
                        height: auto;
                    }
                }
            `}</style>
        </div>
    );
}

export default BiznesSection;
