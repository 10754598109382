import React from 'react';
import './AktywneTextSection.css';
import '../i18n';
import { useTranslation } from 'react-i18next';

function AktywneTextSection() {

  const { t } = useTranslation();

  return (
    <div className="aktywne-text-section">
      <h1>{t('text_section.czym_są_aktywne_miasta')}</h1>
      <p><strong>{t('text_section.Aktywne_miasta')}</strong>{t('text_section.opis1')}</p>
      <p>{t('text_section.opis2')}</p>
    </div>
  );
}

export default AktywneTextSection;
