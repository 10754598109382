import React, { useEffect } from "react";
import './NewsDetail.css';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { marked } from 'marked';
import { useQuery } from '@apollo/client';
import apolloClient from '../queries/Apollo';
import { GET_NEWS_DETAIL } from '../queries/GraphQL_Query';
import LoadingIndicator from "./LoadingIndicator";
import { useParams } from "react-router-dom";

function formatDate(isoDate) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = new Date(isoDate).toLocaleDateString('pl-PL', options);
    return formattedDate;
}

function NewsDetail() {

    const { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const location = useLocation();
    const baseUrl = "https://aktywne.miasta.pl/media/";
    const basePhotoUrl = "https://aktywne.miasta.pl/";
    const { name, date, description, imageUrl, attachments } = location.state || {};

    const { loading, error, data } = useQuery(GET_NEWS_DETAIL, {
        variables: { id },
        skip: !!name,
        client: apolloClient,
    });

    if (loading) return <LoadingIndicator />;
    if (error) return <p>Error: {error.message}</p>;

    const mapDataToNews = (data) => {
        const mainImage = data.allPageEntries.nodes[0].pageEntryPhotosByEntryId.nodes.find(node => node.pagePhotoByPhotoId.mainImage);
        const imageUrl = mainImage ? baseUrl + mainImage.pagePhotoByPhotoId.file : null;
        return {
            name: data.allPageEntries.nodes[0].name,
            date: data.allPageEntries.nodes[0].date,
            description: data.allPageEntries.nodes[0].description,
            imageUrl: imageUrl,
            attachments: data.allPageEntries.nodes[0].pageEntryAttachmentsByEntryId.nodes
        };
    }

    const news = data ? mapDataToNews(data) : { name, date, description, imageUrl, attachments };

    const dom = new DOMParser().parseFromString(news.description, 'text/html');
    dom.querySelectorAll('img').forEach(img => {
        const src = img.getAttribute('src');
        if (src.startsWith('media/')) {
            img.setAttribute('src', basePhotoUrl + src);
        }
    });
    const modifiedDescription = dom.body.innerHTML;

    const html = marked.parse(modifiedDescription);
    

    return (
        <div>
            <div className="news-detail">
                <h1>{news.name}</h1>
                <div className="news-detail-date">
                <p className="date">{formatDate(news.date)}</p>
                </div>
                <div className="news-detail-item">
                    <img src={news.imageUrl} alt={news.name} />
                </div>
                <div className="contentHTML" style={{ display: 'block' }}>
                    {parse(html)}
                </div>
                <div>
                    {news.attachments && news.attachments.map((attachment, index) => (
                        <a key={index} href={baseUrl + attachment.pageAttachmentByAttachmentId.file} download>
                            {attachment.pageAttachmentByAttachmentId.name}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default NewsDetail;