
import React from "react";
import './HelpSection.css';
import { useNavigate } from "react-router-dom";
import '../i18n';
import { useTranslation } from 'react-i18next';

function HelpSectionItem({ index, id, name, description, pageHelpdeskAttachmentsByHelpdeskId }) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/help/${id}`, { 
            state: { 
                name, 
                description,
                attachments: pageHelpdeskAttachmentsByHelpdeskId.nodes 
            } 
        });
    }

    return (
        <div className="help-item">
            <h3>{index}.</h3>
            <p>{name}</p>
            <div className="help-item-button-container">
                <button onClick={handleClick} >{t('help_section_item.WIĘCEJ')} &gt;</button>
            </div>
        </div>
    );
}

export default HelpSectionItem;