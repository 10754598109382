import React from "react";
import './RegulaminyRywalizacji.css';

function RegulaminyRywalizacji() {

    return (
        <div>
            {/* Pobrać wpis z kategorią "RegulaminRywalizacji" (sprawdzić ID kategorii) */}
        </div>
    );
}

export default RegulaminyRywalizacji;