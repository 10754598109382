import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [preferredSports, setPreferredSports] = useState([]);
  const [sex, setSex] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [showLocationToFriends, setShowLocationToFriends] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);

  // Wczytywanie danych z localStorage przy pierwszym załadowaniu
  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(parseInt(storedUserId, 10));
      setFirstName(localStorage.getItem('firstName'));
      setLastName(localStorage.getItem('lastName'));
      setEmail(localStorage.getItem('email'));
      setDateOfBirth(localStorage.getItem('dateOfBirth'));
      setHeight(localStorage.getItem('height'));
      setWeight(localStorage.getItem('weight'));
      setCityId(localStorage.getItem('cityId'));
      const sports = localStorage.getItem('preferredSports');
      setPreferredSports(sports ? JSON.parse(sports) : []);
      setSex(localStorage.getItem('sex'));
      setProfileImage(localStorage.getItem('profileImage'));
      setSessionId(localStorage.getItem('sessionId'));
      setShowLocationToFriends(localStorage.getItem('showLocationToFriends') === 'true');
      setUnreadMessages(parseInt(localStorage.getItem('unreadMessages'), 10) || 0);
    }
  }, []);

  // Funkcja do zapisu danych użytkownika
  const saveUserData = (data) => {
    setUserId(data.id);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setEmail(data.email);
    setDateOfBirth(data.date_of_birth);
    setHeight(data.height);
    setWeight(data.weight);
    setCityId(data.cities_id);
    setPreferredSports(data.prefered_sports);
    setSex(data.sex);
    setProfileImage(data.img);
    setSessionId(data.session_id);
    setShowLocationToFriends(data.show_my_location_to_friends);
    setUnreadMessages(data.unread_messages);

    // Zapis w localStorage
    localStorage.setItem('userId', data.id);
    localStorage.setItem('firstName', data.first_name);
    localStorage.setItem('lastName', data.last_name);
    localStorage.setItem('email', data.email);
    localStorage.setItem('dateOfBirth', data.date_of_birth);
    localStorage.setItem('height', data.height);
    localStorage.setItem('weight', data.weight);
    localStorage.setItem('cityId', data.cities_id);
    localStorage.setItem('preferredSports', JSON.stringify(data.prefered_sports));
    localStorage.setItem('sex', data.sex);
    localStorage.setItem('profileImage', data.img);
    localStorage.setItem('sessionId', data.session_id);
    localStorage.setItem('showLocationToFriends', data.show_my_location_to_friends);
    localStorage.setItem('unreadMessages', data.unread_messages);
  };

  const clearUserData = () => {
    localStorage.clear();
    setUserId(null);
    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setDateOfBirth(null);
    setHeight(null);
    setWeight(null);
    setCityId(null);
    setPreferredSports([]);
    setSex(null);
    setProfileImage(null);
    setSessionId(null);
    setShowLocationToFriends(false);
    setUnreadMessages(0);
  };

  return (
    <UserContext.Provider value={{
      userId, firstName, lastName, email, dateOfBirth, height, weight, cityId, preferredSports, sex, profileImage, sessionId, showLocationToFriends, unreadMessages, saveUserData, clearUserData
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
