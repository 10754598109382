import React from "react";
import "./AktywneIntSection.css";
import { useQuery } from '@apollo/client';
import apolloClient from '../queries/Apollo';
import { GET_USERS_COUNT, GET_PARTNER_CITY, GET_COMPETITIONS } from '../queries/GraphQL_Query';
import Counter from "./features/Counter";
import Icon1 from "../assets/icons/people.png";
import Icon2 from "../assets/icons/numbers.png";
import Icon3 from "../assets/icons/house.png";
import Icon4 from "../assets/icons/tree.png";
import '../i18n';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from './LoadingIndicator';

function AktywneIntSection() {

    const { t } = useTranslation();
    const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_USERS_COUNT, {
        client: apolloClient,
        fetchPolicy: "no-cache",
    });
    const currentDate = new Date().toISOString().split('T')[0];
    const { loading: partnerLoading, error: partnerError, data: partnerData } = useQuery(GET_PARTNER_CITY, {
        variables: { greaterThan: currentDate },
        client: apolloClient,
        fetchPolicy: "no-cache",
    });
    const { loading: competitionsLoading, error: competitionsError, data: competitionsData } = useQuery(GET_COMPETITIONS, {
        client: apolloClient,
        fetchPolicy: "no-cache",
    });

    if (usersLoading || partnerLoading || competitionsLoading) return <LoadingIndicator />;
    if (usersError || partnerError || competitionsError) return <p>Błąd: {usersError?.message || partnerError?.message || competitionsError?.message}</p>;
    const usersCount = usersData?.allAccountsUsers?.totalCount || 0;
    const citiesCount = partnerData?.allPagePartners?.totalCount || 0;
    const competitionsCount = competitionsData?.allActivitiesCompetitions?.totalCount || 0;

    return (
        <div className="aktywne-int-section">
            <h1><strong>{t('int_section.aktywne_miasta')}</strong>{t('int_section.w_liczbach')}</h1>

            <div className="columns-container">
                <div className="column">
                    <div className="icon">
                        <img src={Icon1} alt="Ikona 1" />
                    </div>
                    <div className="stext">{t('int_section.liczba_uzytkownikow')}</div>
                    <Counter targetNumber={usersCount} />
                </div>
                <div className="column">
                    <div className="icon">
                        <img src={Icon2} alt="Ikona 2" />
                    </div>
                    <div className="stext">{t('int_section.liczba_rywalizacji')}</div>
                    <Counter targetNumber={competitionsCount} />
                </div>
                <div className="column">
                    <div className="icon">
                        <img src={Icon3} alt="Ikona 3" />
                    </div>
                    <div className="stext">{t('int_section.liczba_miast')}</div>
                    <Counter targetNumber={citiesCount} />
                </div>
                <div className="column">
                    <div className="icon">
                        <img src={Icon4} alt="Ikona 4" />
                    </div>
                    <div className="stext">{t('int_section.slad_smogowy')}</div>
                    <Counter targetNumber={4348} />
                </div>
            </div>
        </div>
    );
}

export default AktywneIntSection;
